

.heteroLeftLogo {
    position: fixed;
    top: 1.5%;
    left: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    filter: grayscale(50%) brightness(1.5) contrast(0.7);
    transform: scale(1);
    transition: filter 0.6s ease-in-out, transform 0.6s ease-in-out;
  }
  
  .heteroLeftLogo:hover {
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
  }

  .heteroLeftLogo-mobile {
    position: fixed;
    top: 2%;
    left: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 5vh;
    height: 5vh;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }