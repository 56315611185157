


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  /* 20% { transform: translate(-3px, 0px) rotate(1deg); } */
  30% { transform: translate(3px, 2px) rotate(0deg); }
  /* 40% { transform: translate(1px, -1px) rotate(1deg); } */
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  /* 60% { transform: translate(-3px, 1px) rotate(0deg); } */
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  /* 80% { transform: translate(-1px, -1px) rotate(1deg); } */
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.rigHeteroLogo{
    position: fixed;
    /* width: 150px;
    height: 150px; */
    top: 4%;
    right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 5vw;
    height: 5vh;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    filter: grayscale(50%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }
  
  @media screen and (max-width: 560px) {
    .rigHeteroLogo{
      position: fixed;
      /* width: 150px;
      height: 150px; */
      top: 1%;
      right: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 4rem;
      height: 4rem;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 10;
      filter: grayscale(50%) brightness(1.5) contrast(.7);
      transform: scale(1) ;
      transition: filter .6s ease-in-out,
                  transform .6s ease-in-out;
    }
  }
  
  
  .rigHeteroLogo:hover{
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
  }
  .rigHeteroLogo:hover + .app{
      /* Start the shake animation and make the animation last for 0.5 seconds */
      
      animation: shake 0.5s;
    
      /* When the animation is finished, start again */
      animation-iteration-count: infinite;
    
    
    }