.footerContainer{
    bottom:0;
    width:100%;
    background-color:transparent;
    height: 40%;
    color: white;
    padding: 15rem;
}

/*  Blip Text on Footer */
.blip-text {
    padding-top: 5px !important;
    bottom: 0;
    /* Padding top */
    /* padding-top: 100px; */
    /* font-size: 1.8rem; */
    font-weight: bold;
    /* left: 25%; */
    position: sticky;
    /* top: 33%; */
    transform: translateY(-50%);
    /* width: 50%; */
    font-size: 18px;
    color: grey !important;
  }
  .blip-text:nth-of-type(1) {
    top: 50%;
  }
  .blip-text:last-of-type {
    top: 66%;
  }
  @media (max-width: 600px) {
    .blip-text {
      left: 10%;
      width: 80%;
      padding-top: 15%;
      font-size: 14px;
    }
  }
 /*  Footer Layout */
  .site-footer
    {
    /* background-color:#26272b; */
    background-color: transparent;
    padding-top: 20vh;
    font-size:18px;
    line-height:24px;
    color:#737373;
    /* margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 1%;
    margin-top: 5%; */
    position: sticky;
    bottom: 0;
    text-align: left;
    
    }
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h7
{
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: underline;
  color:#fff;
  font-size:10px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
}
.site-footer h6
{
  color:rgb(208, 208, 208);
  font-size:17px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  font-family: "Staatliches";
}
.site-footer h5
{
  color:#fff;
  font-size:17px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  font-family: "Staatliches";
}

.site-footer h5:hover
{
  color:rgb(144,28,124);
  font-size:17px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  font-family: "Staatliches";
} 

.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.site-footer p
{
  font-size: 14px;
}
.site-footer li
{
  list-style-type: none;
  text-align: center;

}


/*  */

/* Social Media */
.flex-center {
  /* width: 100%; */
  /* min-height: 50vh; */
  background: transparent;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
 
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position:absolute;
  bottom:0;
  right:0;
  font-size: 1.5vmin;
}

@media screen and (max-width:720px){
  .flex-center {
    background: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position:absolute;
    bottom:0;
    right:0;
    left: 0;
    /* padding-top: 10%;
    padding-bottom: 10%; */
    padding-bottom: 8%;
    font-size: 3vw;
  }
 
}

.icon-3d {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  -webkit-animation: icon3d 200ms 10;
  animation: icon3d 200ms 10;
  color: #fff;
}
.icon-3d:hover {
  -webkit-animation: icon3d 200ms infinite;
  animation: icon3d 200ms infinite;
}
@keyframes icon3d {
  0% {
    text-shadow: 5px 4px #ff6030, -5px -6px #1b3984;
  }
  25% {
    text-shadow: -5px -6px #ff6030, 5px 4px #1b3984;
  }
  50% {
    text-shadow: 5px -4px #ff6030, -8px 4px #1b3984;
  }
  75% {
    text-shadow: -8px -4px #ff6030, -5px -4px #1b3984;
  }
  100% {
    text-shadow: -5px 0 #ff6030, 5px -4px #1b3984;
  }
}

.termsFaqPrivacy{
  /* padding-top: 10% !important; */
  /* padding-top: 10%;
  padding-left: 7px;
  padding-right: 7px; */
  padding-bottom: 2rem;
  font-family: "Staatliches";
}
.termsFaqPrivacy:hover{
  /* padding-top: 10% !important; */
  /* padding-top: 10%;
  padding-left: 7px;
  padding-right: 7px; */
  padding-bottom: 2rem;
  font-family: "Staatliches";
}

.bottomSheetText:hover{
 color: rgb(144,28,124) !important;
} 

.footer-links{
  text-align: left;
  align-items: flex-start;
  align-self: start;
  padding-right: 3rem;
  width: 100%;
}


@media screen and (max-width:720px){
  .termsFaqPrivacy{
    /* padding-top: 10% !important; */
    padding-top: 1%;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 6rem;
    font-family: "Staatliches";
  }
  
 
}

@media screen and (max-width:720px){
  .disclaimerContent{
    /* padding-top: 10% !important; */
    /* padding-top: 10%; */
    padding-left: 12px;
    padding-right: 12px;
  }
  
 
}

