
.explore-center-div{ 
    left: 38%;
    top: 40%;
    width:20vw; height:20vh; display:flex; position: fixed; justify-content:center; align-items:center; background: transparent;
    z-index: 1;
      transition: opacity 1.5s, visibility 1.5s;
}
/* 

.buttonExplore {background:#030808; color:#f8f8f8; font-family: "heteroFont"; font-size:2em; display:inline-block; height:160px; width:160px; line-height:160px; border-radius:50%; text-align:center; text-decoration:none; position:relative; transition:1s ease;}
.buttonExplore::before, .buttonExplore::after {content:''; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%) scale(0); border-radius:50%; border:1px solid #143A3D; z-index:-5; transition:0.6s ease-out;}
.buttonExplore:hover, .buttonExplore:focus  {color:#d930ce}
.buttonExplore::before {width:calc(100% + 40px); height:calc(100% + 40px);}
.buttonExplore::after {width:calc(100% + 100px); height:calc(100% + 100px);}
.buttonExplore:hover::before, .buttonExplore:hover::after, .buttonExplore:focus::before, .buttonExplore:focus::after  {transform:translate(-50%,-50%) scale(1);}

.effect {position:absolute; border-radius:50%; left:50%; top:50%; transform:translate(-50%,-50%) scale(0.5); transition:0.6s ease-out; z-index:-1; width:0; height:0;}
.effect.circle-1 {animation:gravitate 4s linear infinite;}
.effect.circle-2 {animation:gravitate 8s linear infinite;}

.buttonExplore:hover .circle-1, .buttonExplore:focus .circle-1 {width:100%; height:100%;}
.buttonExplore:hover .circle-2, .buttonExplore:focus .circle-2 {width:calc(100% + 30px); height:calc(100% + 30px);}

.effect::before, .effect::after {content:''; position:absolute; border-radius:50%;}
.effect.circle-1:before {left:0; top:0; width:20px; height:20px; background:#720b64;}
.effect.circle-2:before {right:-15px; bottom:-15px; width:35px; height:35px; background:#d505ce;}

@keyframes gravitate {
  0% {transform:translate(-50%,-50%) rotate(0deg);}
  100% {transform:translate(-50%,-50%) rotate(360deg);}
} */



.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pulse{
    width: 460px;
    height: 460px;
    background: transparent;
    border-radius: 50%;
    /* border: #b3acac 0.5px solid; */
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    line-height: 160px;
    font-family: "heteroFont";
    text-transform: uppercase;
    animation: animate-pulse 4s linear infinite;
    cursor: pointer;
    box-shadow: 5px 10px #000000;
    display:flex;  justify-content:center; align-items:center;
}
@keyframes animate-pulse{
    0%{
        box-shadow: 0 0 0 0 rgb(144,28,124,0.7),  0 0 0 0 rgb(144,28,124,0.7);
    }
    40%{
        box-shadow: 0 0 0 60px rgb(144,28,124,0.7),  0 0 0 0 rgb(144,28,124,0.7);
    }
    80%{
        box-shadow: 0 0 0 60px rgba(249, 57, 217, 0),  0 0 0 60px rgba(249, 57, 217, 0);
    }
    100%{
        box-shadow: 0 0 0 0 rgba(249, 57, 217, 0),  0 0 0 60px rgba(249, 57, 217,0);
    }
    
}

