

.container{
    padding-top: 10%;
}

@media screen and (max-width: 560px) {
  .container{
    padding-top: 30%;
    padding-left:7px;
}

}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.canvas-mobile{
  width: 110% !important;
  position: relative;
  left: -1;
}


.app{
  width: 100%;
  height: 100%;
  position: fixed;
}

.custom-cursor {
  background-image: url("/public/starter_iconss/begin.png");
  background-position: center;
  width: 100px;
  height: 50px;
}

.mouse-popover{
  background-color: transparent !important;
  color: aliceblue !important;
  font-size: 16rem !important;
  /* font-family: "heteroFont" !important; */
}
.welcome-mid-button{
  width: 190px;
  height: 120px;
  background-color: transparent !important;
  outline: none !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -ms-touch-action: manipulation !important;
      touch-action: manipulation !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  background-image: none !important;
  border: 0px solid transparent !important;
  border-radius: 70% !important;
}
.welcome-mid-button:active{
  border-style: outset !important ;
  box-shadow: none !important;
}
.welcome-mid-button:focus{
  border-style: outset !important;
  box-shadow: none !important;
}
.galaxy{
  width: 100% !important;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  border: none;
  z-index: 1;
  /* cursor: url("https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/begin%20(1).png?alt=media&token=31a94b6b-9a69-4049-bdbe-0a8f363ec915"), default !important; */
}

.galaxy-mobile{
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  border: none;
  cursor: url("https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/begin%20(1).png?alt=media&token=31a94b6b-9a69-4049-bdbe-0a8f363ec915"), default !important;
}


.welcomeEarth{
  position: fixed;
  /* width: 150px;
  height: 150px; */
  top: 1%;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120px;
  height: 120px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  filter: grayscale(50%) brightness(1.5) contrast(.7);
  transform: scale(1) ;
  transition: filter .6s ease-in-out,
              transform .6s ease-in-out;
}



@media screen and (max-width: 720px) {
  .welcomeEarth{
    width: 90px;
    height: 90px;
    position: fixed;
    /* width: 150px;
    height: 150px; */
    top: 1%;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    filter: grayscale(50%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }
  
  }

@media screen and (max-width: 560px) {
.welcome-youtube-video{
  /* bottom: 1%; */
  right: 1%;
  padding: 2rem;
  position: fixed;
  z-index: 2;
  width: 70px;
  height: 70px;
}

}

.mobile-mid-container{ 
    height: auto;
    top: 15rem;
    display: flex;  
    justify-content: center;  
    align-items: center;  
    position: relative;
}

.mobile-mid-button {  
  background-color: lightblue;  
  color: black;  
  font-size: 25px;  
  align-items: center;
  text-align: center;
  align-self: center;
  }  

  /* Vertical Timeline --------------------------------------- */
.input-group-custom{
  display: flex;
  
  background-color: transparent !important;

  align-items: center;
}

.input-group-custom-2{
  display: flex;
  background-color: transparent !important;

  align-items: center;
}


  #vertical-timeline{
    list-style:none;
    padding:0;
    margin:0;
    position:absolute;
    top: 10%;
    left:1.5rem;
    height: 100vh;
    z-index: 1;
  }

  #vertical-timeline:after{
  content:'';
  /* border: 1px solid rgba(31, 31, 31, 0.7); */
  height:100%;
  position:absolute;
  top:4px;
  left:12px;
  z-index:-1;
  }

  #vertical-timeline h6{
    margin-left: 5px;
    font-size: 1.5vh;
    color: transparent;
    font-family: 'Staatliches';
  }

  .marker-dune{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/01-dune-tomato.png") ;
    background-position: center;
    background-size: 100% auto;
    transition: 0.3s ease-in;
    /* width: 2.2vh;
    height: 2.2vh; */
    /* background-color: tomato; */
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
    
  }
  .marker-dune:focus{
    /* width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/01-dune-tomato.png") ;
    background-position: center;
    border: 1px solid tomato;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out; */
    
  }

  .marker-tunel{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/03-tunnel-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }

  .marker-tunel-focus{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/03-tunnel-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
  }

  .marker-asteroid{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/04-HeteroMeta-Beginning-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out; 
  }

  .marker-asteroid-focus{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/04-HeteroMeta-Beginning-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
  }


  .marker-matrix{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/07-earth2.0-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }

  .marker-matrix-focus{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/07-earth2.0-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
  }


  .marker-beyondmarket{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/08-matrix-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }

  .marker-beyondmarket-focus{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/08-matrix-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(2.5) contrast(.7);
    transform: scale(1) ;
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
  }
  

  .marker-yuvarlak{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/05-HeteroMeta-Halo-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }

  .marker-heteroemta{
    width:4.5vh;
    height:4.5vh;
    margin-left: 0.6vh;
    color: transparent;
    border: 0px solid black;
    background-color: rgb(0, 0, 0,0.2);
    border-radius: 50%;
    background-image: url("/public/scroll/08-matrix-tomato.png");
    background-position: center;
    background-size: 100% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }

  .major-duration-1{
  margin-top:1vh;
  /* margin-bottom: 5vh; */
  }
   .minor-duration-1{
    margin-top: 2px !important;
    /* margin-left: 2.5px; */
    margin-left: 0.1vh;
  }
   .minor-marker-1{
    cursor: pointer;
    width:2.2vh;
    height:2.2vh;
    left: 1.6vh;
    position: relative;
    display:block;
    text-align:center;
    line-height:30px;
    border: 1px solid transparent;
    border-radius:50%;
    z-index:1;
    background-color:transparent;
    color: transparent;
    background-image: url("/public/scroll/minors-on_Äalçüma\ YÅzeyi\ 1.png");
    background-position: center;
    background-size: 200% auto;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    transform: scale(1) ;
    transition: filter .6s ease-in-out,
                transform .6s ease-in-out;
  }
  .minor-marker-focus{
    cursor: pointer;
    width:2.2vh;
    height:2.2vh;
    left: 1.6vh;
    position: relative;
    display:block;
    text-align:center;
    line-height:30px;
    border-radius:50%;
    z-index:1;
    background-color:transparent;
    color: transparent;
    background-image: url("/public/scroll/minors-on_Äalçüma\ YÅzeyi\ 1.png");
    background-position: center;
    background-size: 200% auto;
    cursor: pointer;
    transition: 0.3s ease-in;
    /* background-color: tomato; */
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
  }
 
 

  .major-duration-2{
    margin-top: 0px;
    /* margin-bottom: 5vh; */
    /* margin-left: 1vh */
    }
     .minor-duration-2{
      /* margin-top: 2vh !important; */
      margin-left: 0.1vh;
    }
     .minor-marker-2{
      width:2.2vh;
      height:2.2vh;
      left: 1.6vh;
      position: relative;
      display:block;
      text-align:center;
      line-height:30px;
      border: 1px solid transparent;
      border-radius:50%;
      z-index:1;
      background-color:transparent;
      color: transparent;
      background-image: url("/public/scroll/minors-on_Äalçüma\ YÅzeyi\ 1.png");
      background-position: center;
      background-size: 200% auto;
      filter: grayscale(100%) brightness(1.5) contrast(.7);
      transform: scale(1) ;
      transition: filter .6s ease-in-out,
                  transform .6s ease-in-out;
      
    }
    
  
    .major-duration-3{
      /* margin-top:2vh; */
      /* margin-bottom: 5vh; */
      margin-left: 0px;
      }
       .minor-duration-3{
        /* margin-top: 2vh !important; */
        /* margin-left: 2.5px; */
        margin-left: 0.1vh;
      }
       .minor-marker-3{
        width:2.2vh;
        height:2.2vh;
        left: 1.6vh;
        position: relative;
        display:block;
        text-align:center;
        line-height:30px;
        border: 1px solid transparent;
        border-radius:50%;
        z-index:1;
        background-color:transparent;
        color: transparent;
        background-image: url("/public/scroll/minors-on_Äalçüma\ YÅzeyi\ 1.png");
        background-position: center;
        background-size: 200% auto;
        filter: grayscale(100%) brightness(1.5) contrast(.7);
        transform: scale(1) ;
        transition: filter .6s ease-in-out,
                    transform .6s ease-in-out;
        
      }
  
      .major-duration-4{
        /* margin-top:2vh; */
        /* margin-bottom: 5vh; */
        margin-left: 0px;
        }
         .minor-duration-4{
          /* margin-top: 2vh !important; */
          margin-left: 0.1vh;
          
        }
         .minor-marker-4{
          width:2.2vh;
          height:2.2vh;
          left: 1.6vh;
          position: relative;
          display:block;
          text-align:center;
          line-height:30px;
          border: 1px solid transparent;
          border-radius:50%;
          z-index:1;
          background-color:transparent;
          color: transparent;
            background-image: url("/public/scroll/minors-on_Äalçüma\ YÅzeyi\ 1.png");
          background-position: center;
          background-size: 200% auto;
          filter: grayscale(100%) brightness(1.5) contrast(.7);
          transform: scale(1) ;
          transition: filter .6s ease-in-out,
                      transform .6s ease-in-out;
          
        }
  
        #vertical-timeline li .minor{
          transition: 0.15s ease-in;
          margin-top: 0.7vh;
          margin-bottom: 0.7vh;
        /* margin-top:10vh;
        margin-bottom: 10vh; */
        /* width:15px;
        height:15px;
        display:block;
        text-align:center;
        line-height:30px;
        border: 1px solid white;
        border-radius:50%;
        z-index:1;
        background-color:transparent;
        color: transparent; */
        }

  #vertical-timeline li .marker{
    transition: 0.15s ease-in;
    margin-top: 0.7vh;
    margin-bottom: 0.7vh;
  /* margin-top:10vh;
  margin-bottom: 10vh; */
  /* width:15px;
  height:15px;
  display:block;
  text-align:center;
  line-height:30px;
  border: 1px solid white;
  border-radius:50%;
  z-index:1;
  background-color:transparent;
  color: transparent; */
  }
  #vertical-timeline li .marker:hover{
    cursor: pointer;
    transition: 0.15s ease-in;
    /* width: 5vh;
    height: 5vh; */
    /* margin-bottom: 1.2vh; */
    /* background-color: tomato; */
    border: 1px solid transparent;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1.15);
    cursor: pointer;
  }
  #vertical-timeline li .minor:hover{
    cursor: pointer;
    transition: 0.2s ease-in;
    /* width: 2.5vh;
    height: 2.5vh; */
    /* background-color: tomato; */
    /* border: 1px solid tomato; */
    /* margin-top: 0.4vh;
    margin-bottom: 0.4vh; */
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1.2);
    cursor: pointer;
    /* border: 0.5px solid tomato; */
  }
  #vertical-timeline li .minor:focus{
    cursor: pointer;
    transition: 0.3s ease-in;
    width: 2.2vh;
    height: 2.2vh;
    /* background-color: tomato; */
    border: 1px solid tomato;
    filter: grayscale(0%) brightness(1) contrast(1);
    transform: scale(1);
    cursor: pointer;
    border: 0.5px solid tomato;
    outline: none;
  ;
  }
  #vertical-timeline li .marker:focus{
      cursor: pointer; 
      transition: 0.4s ease-in;
      /* background-color: tomato; */
      border: 0.5px solid tomato;
      outline: none;
      width:4.5vh;
    height:4.5vh;
      filter: grayscale(0%) brightness(1) contrast(1);
      transform: scale(1.2);
      cursor: pointer;
      color: transparent;
  }

  #vertical-timeline li .marker:focus +  h6{
    cursor: pointer;
    transition: 0.4s ease-in;
    font-family: "Staatliches";
    color: tomato;
  }

  #vertical-timeline li .marker:hover +  h6{
      cursor: pointer;
      color: tomato;
      transition: 0.2s ease-in;
      font-family: "Staatliches";
      /* padding-left: 0.7vh; */
      
  }

  #vertical-timeline li .contentMarker{
    /* margin-left:70%;
    margin-top:-25%; */
    height: 0;
    width: 10px;
    color: transparent;
    background-color: red;
  
  }

  .vertical-progress-minor-1{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }

  .vertical-progress-minor-1-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-2{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;
  }
  .vertical-progress-minor-2-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-3{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-3-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-4{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-4-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-5{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-5-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-6{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-6-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-7{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
   .vertical-progress-minor-7-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-8{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-8-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-9{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-9-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-10{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-10-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-11{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-11-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-12{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-12-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-13{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-13-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-14{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-14-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }
  .vertical-progress-minor-15{
    border-right: 2px solid tomato;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;
  }
  .vertical-progress-minor-15-grey{
    border-right: 2px solid grey;
    height: 2vh;
    width: 2.5vh;
    margin-left: 4px;

  }

  .contentMarker{

  }

  /* #vertical-timeline li .contentMarker:hover{
    margin-left:50px;
    margin-top:-25px;
    color: tomato;
    } */
  


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  /* 20% { transform: translate(-3px, 0px) rotate(1deg); } */
  30% { transform: translate(3px, 2px) rotate(0deg); }
  /* 40% { transform: translate(1px, -1px) rotate(1deg); } */
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  /* 60% { transform: translate(-3px, 1px) rotate(0deg); } */
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  /* 80% { transform: translate(-1px, -1px) rotate(1deg); } */
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.video-container-stop{
  cursor: pointer;
  filter: grayscale(45%);
  /* transition: filter .6s ease-in-out,
  transform .6s ease-in-out;
  transform: .6s ease-in-out; */
  transition: all 0.6s ease-in;
  /* width: 100%;
  height: 100%; */
  position: fixed;
  z-index: 0;
}

.video-container{
  cursor: pointer;
  /* transition: filter .6s ease-in-out,
  transform .6s ease-in-out;
  transform: .6s ease-in-out; */
  transition: all 0.9s ease-in;
  /* width: 100%;
  height: 100%; */
  position: fixed;
  z-index: 0;
}

.setHeight{
  height: 20000px;
  width: 100vw;
  justify-content: center;
}

.video-background-react-player-mobile{
  width: 100%;
  height: 100%;

}

.video-background-mobile {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 90%;
  transform: translate(-50%, -50%);
  overflow: overlay !important;
}


.welcome-youtube-video{
  position: fixed;
  bottom: 1%;
  right: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 130px !important;
  height: 130px !important;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  border-radius:50%;
  background-image: url("/public/starter_iconss/watch.png");
  background-position: center;
  background-size: 100% auto;
}

.welcome-youtube-video :hover{

  filter: grayscale(100%) brightness(2.5) contrast(.7);
  transform: scale(1.1) ;
  transition: filter .6s ease-in-out,
              transform .6s ease-in-out;
}

.welcome-youtube-video:hover + .youtube-video-panel{
  opacity: 1;
  /* filter: grayscale(100%) brightness(2.5) contrast(.7); */
  /* transform: translateY(-100%) ; */
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
              z-index: 11;
  transition: opacity 1.5s, visibility 1.5s;
}

.youtube-video-panel{
  position: fixed;
  bottom: 8%;
  right: 5%;
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  /* margin: 5vw; */
  z-index: 0;
  opacity: 0;
  /* transform: translateX(0) translateY(0) ; */
  transition: filter .6s ease-in-out,
              transform .6s ease-in-out;
}

.youtube-video-panel:hover{
  z-index: 11;

   opacity: 1;
  /* transform: translateY(-100%) ; */
  transition: filter .6s ease-in-out,
              transform .6s ease-in-out;
              z-index: 11;
  transition: opacity 1.5s, visibility 1.5s;
  /* bottom: 0;
  right: 0; */
}



.hetero-legt-welcome{
  position: fixed;
  bottom: 37%;
  right: 45%;
  z-index: 0;

}


.hetero-legt-welcome-mobile{
  position: fixed;
  bottom: 37%;
  right: 35%;
  z-index: 0;
  text-align: center;

}

.hetero-legt-welcome-title{
  font-size: 1.2vmin;
  font-family: "heteroFont" !important;
  text-decoration: none;
  color: white;
}

.hetero-legt-welcome-title-mobile{
  font-size: 2vmin;
  font-family: "heteroFont";
  text-decoration: none;
  color: rgb(212, 212, 212);
  z-index: 13;
}


.hetero-legt-welcome-subtitle{
  font-size: 1.5vmin;
  font-family: "heteroFont" !important;
  text-decoration: none;
  color: whitesmoke;
  width: 50%;
}

.hetero-left-welcome-logo {
  position: fixed;
  bottom: 2%;
  left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120px;
  height: 120px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 0;
}


@media screen and (max-width: 560px) {
  .hetero-left-welcome-logo {
    position: fixed;
    bottom: 5%;
    left: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90px;
    height: 90px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 0;
  }

  .hetero-legt-welcome-title{
    font-size: 2.4vmin;
    font-family: "heteroFont" !important;
    text-decoration: none;
    color: white;
  }
  
  .welcome-youtube-video{
    position: fixed;
    bottom: 0%;
    right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20vw;
    height: 20vh;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  
}

.top-center-continue-text {
  position: absolute;
  top: 4%;
  left: 43%;
  color: white;
  z-index: 0;
  font-family: 'Staatliches';
  align-items: center;
  text-align: center;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
  font-size: 4vmin;
  cursor: pointer;
   -webkit-animation: flickerAnimation 2s infinite;
   -moz-animation: flickerAnimation 2s infinite;
   -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.animate-flicker {
  font-size: 3vmin;
  font-family: "heteroFont" !important;
  text-decoration: none;
  color: white;
   -webkit-animation: flickerAnimation 2s infinite;
   -moz-animation: flickerAnimation 2s infinite;
   -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.img-tap-to-continue{
  width: 40px;
  height: 40px;
  padding-bottom: 10px;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
   animation: flickerAnimation 2s infinite;
}



.vl {
  width: 2.5rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.cardImage{
  width: 100%;
  height: 180px;
  padding-left: 15px;
  
}

.cardImageContent{
  width: 100%;
  height: 720px;
  padding-left: 15px;
  
}
@media screen and (max-width: 720px) {
  .cardImageContent{
    width: 100%;
    height: 720px;
    padding-left: 0px;
    
  }
}

.lookAroundpng{
  width: 4.7vmin;
  height: 4.7vmin;
  align-items: center;
  text-align: center
}

@media screen and (max-width: 720px) {
  .lookAroundpng{
    width: 35px;
    height: 35px;
    align-items: center;
    text-align: center
  }
}


/* Map1 */

.map1{
  text-align: center;
  position: fixed;
  height: 80vh;
  width: 80vw;
  top: 10vh;
  left:10vw;
}



/* title Text */
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat');

@font-face {
  font-family: 'heteroFont'; /*a name to be used later*/
  src: url('../public/fonts/Andromeda-eR2n.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'heteroFont'; /*a name to be used later*/
  src: url('../public/fonts/Andromeda-eR2n.ttf'); /*URL to font*/
}

  .cardTitle{
    font-family: 'heteroFont';
    font-size: 3vmin !important;
    color: white;
    text-decoration: none;

  }

  .cardTitle:hover{
    font-family: 'heteroFont';
    font-size: 3vmin;
    color: rgb(144,28,124);
    cursor: pointer;
  }

  @media screen and (max-width: 560px) {
    .cardTitle{
      font-family: 'heteroFont';
      font-size: 24px !important;
    }
  }

  .cardContent{
    font-family: 'Montserrat';
    font-size: 22px;
    color: rgb(203, 203, 203);
    line-height: 1.9;

  }

  @media screen and (max-width: 560px) {
   .cardContent{
    font-family: 'Montserrat';
    font-size: 3.5vmin;
    color: rgb(203, 203, 203);
  }
  }
  .cardQuota{
    font-family: 'Montserrat';
    font-size:  large;

  }

  .readMore{
    font-size: 1rem;
    font-weight: 500;
    /* border-bottom: 0.5px solid grey; */
    display: inline-block;
    padding-bottom: 3px;
    font-family: sans-serif;
    text-decoration: none;
    color: grey;

  }
  .readMore:hover{
    text-decoration: none;
    color: rgb(144,28,124);
    border-bottom: 3px solid rgb(144,28,124);
  }
  

.titleText{
  position: relative;
  font-size: 1.6vmin;
  font-family: 'heteroFont';
  text-transform: uppercase;
  font-weight: 900;
  font-display: swap;
  /* text-shadow: 0 0 10px rgb(232, 227, 226); */
  color: rgb(144,28,124);
  text-align: start;
}
@media screen and (max-width: 560px) {
.titleText{
  position: relative;
  font-size: 2.8vmin;
  font-family: 'heteroFont';
  text-transform: uppercase;
  font-weight: 900;
  font-display: swap;
  /* text-shadow: 0 0 10px rgb(232, 227, 226); */
  color: rgb(144,28,124);
  text-align: start;
}
}

.menu-footer-link{
  color: rgb(151, 151, 151) !important;
  font-family: "heteroFont";
  width: 250px;
  font-size: 0.70vw;
  padding-bottom: 10%;
  padding-right: 5%;
  text-align: center;
}

.menu-footer-link-text{
  font-family: "heteroFont";
  font-size: 1.7vmin;
 
}

.social-button{
  background-color: transparent;
}

/* Back Title */
.backTitle{
  color: rgb(144,28,124);
  cursor: pointer;
  text-decoration: none;
}
.backTitle:hover{
  color: rgb(144,28,124);
  cursor: pointer;
  text-decoration: underline;
}
/* Moving Title */
.jt {
  position: relative;
  font-size: 4vmin;
   font-weight: 900;
  font-family: 'heteroFont';
  text-transform: uppercase;
  font-display: swap;
  text-shadow: 0 0 10px rgb(144,28,124);
  color: white;
  text-align: start;
}

@media screen and (max-width: 560px) {
  .jt{
    position: relative;
    font-size: 12vmin;
    /* font-family: 'Staatliches', sans-serif; */
    font-family: 'heteroFont';
    text-transform: uppercase;
    font-display: swap;
    text-shadow: 0 0 10px rgb(144,28,124);
    color: white;
    text-align: start;
 }
}

.jt__row {
  display: block;
}
.jt__row:nth-child(1) {
  clip-path: polygon(-10% 75%, 110% 75%, 110% 110%, -10% 110%);
}
.jt__row:nth-child(2) {
  clip-path: polygon(-10% 50%, 110% 50%, 110% 75.3%, -10% 75.3%);
}
.jt__row:nth-child(3) {
  clip-path: polygon(-10% 25%, 110% 25%, 110% 50.3%, -10% 50.3%);
}
.jt__row:nth-child(4) {
  clip-path: polygon(-10% 0%, 110% 0%, 110% 25.3%, -10% 25.3%);
}

.jt__row.jt__row--sibling {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  /* width:800px; */
}

.jt__text {
  display: block;
  transform-origin: bottom left;
  animation: moveIn 4s 0s cubic-bezier(.36,0,.06,1) alternate infinite ;
}
.jt__row:nth-child(1) .jt__text {
  transform: translateY(-0.1em);
}
.jt__row:nth-child(2) .jt__text {
  transform: translateY(-0.3em) scaleY(1.1);
}
.jt__row:nth-child(3) .jt__text {
  transform: translateY(-0.5em) scaleY(1.2) ;
}
.jt__row:nth-child(4) .jt__text {
  transform: translateY(-0.7em) scaleY(1.3) ;
}
.jt__row:nth-child(5) .jt__text {
  transform: translateY(-0.9em) scaleY(1.4) ;
}
.jt__row:nth-child(6) .jt__text {
  transform: translateY(-1.1em) scaleY(1.5) ;
}

@keyframes moveIn {
  50%, 100% { 
    transform: translateY(0em)
  }
  0%   { 
  opacity: 0; 
  filter: blur(10px);
  
  }
  100% { 
  opacity: 1; 
  filter: blur(0px);
  }
}



.debug .jt__row:nth-child(even) {
  color: black;
  background: white;
}
.debug .jt__row:nth-child(odd) {
  color: white;
  background: black;
}

* { box-sizing: border-box }



/*  Main Video Background SCroll Style  */

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  

.video-background {
  position: fixed;
  top: 50%;
  left: 50%;
  height:90%;
  transform: translate(-35%, -35%);
}

.video-background-react-player{
 z-index: 0;
}

.bottomCenterButton{
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  color: white;
}

.buttonTriangle{
  width: 10x;
  height: 10px;
  background: transparent;
  /* border: 3px solid whitesmoke;
  width: 0;
  height: 0; */
  border-top: 7px solid transparent;
  border-left: 17px solid rgb(203, 203, 203,0.7);
  border-bottom: 7px solid transparent;
  border-right: 0px solid transparent;
  transition: all 0.2s ease-in;
  /* transform:  rotate('45deg'); */
  /* width: 260px;
  height: 35px;
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: 1px solid rgb(67, 3, 3);
  background-color: rgba(105, 35, 111, 0.7); color: black; */
}
.buttonSquare{
  width: 15px;
  height: 15px;
  background: transparent;
  /* border: 3px solid rgb(203, 203, 203,0.7); */
  transition:  all 0.2s ease-in;
  /* transform:  rotate('45deg'); */
  border-style: double;
  border-width: 0px 0px 0px 15px;
  border-color: rgb(203, 203, 203,0.7);
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  /* text-align: center;
  text-decoration: none;
  display: inline-block; */
  /* font-size: 16px;
  margin: 4px 2px; */
  cursor: pointer;
}



 /* ------ Menu Css ------  */

 .menuInfoMobile{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width :100%;
    height: 55px;
    text-align: center;
    justify-content: center;
    padding-left: 50%;

 }
 .menuInfoMobileText{
  color: rgb(205, 196, 196);
  text-align: start;
  padding-top: 5px;
  font-size: 11px;
}

.menuTextItems{
  color: #8026d9;
  font-size: 42px;
}

.menuBottomText{
  color: rgb(176, 176, 176);
  font-size: 1.3vmin;
  padding: 1rem;
  font-family: "heteroFont";
}


 
/* ----- Loading ----- */

.loading span{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:2px solid #fff;
  position-events:none;
  animation: animate 5s linear infinite;
}
.loading span:nth-child(1)
{
  border-radius:60% 40% 21% 79% / 35% 55% 45% 65%  ;
}
.loading span:nth-child(2)
{
  animation-direction:reverse;
  border-radius:50% 50% 51% 49% / 68% 68% 32% 32%;
}
.loading span:nth-child(3)
{
  animation-duration:3s;
  border-radius:32% 68% 32% 68% / 43% 47% 53% 57%;
}
@keyframes animate{
  0%{
   transform:rotate(0deg);
  }
  100%{
    transform:rotate(360deg);
  }
}
.loading h2{
  color:#fff;
  font-family:lato;
  font-weight:500;
}

.loadingContainer{
    position: fixed;
    display:flex;
    justify-content:center;
    align-items:center;
    background:#111;
    min-height:100vh;
    min-width: 100vw;

}

#loading{
  justify-content: center;
  text-align: center;
}



/* --------------Contact ---------------- */
.contactLabel{
  font-family: "Staatliches";
  font-size: 2vmin;
  padding-bottom: 15px;
}

.contactContainer{
  padding-left: 20%;
  padding-right: 20%;
}

.contactContainer-mobile{
  padding-left: 7%;
  padding-right: 7%;
}

/*  Brands */
.brandsDescription{
  font-family: "heteroFont";
  filter: grayscale(0) brightness(1.5) contrast(.7);
  font-size: 24px;
}

.contactFormItem{
  background-color: #111;
}

.submit-button-container{
  /* padding: 2rem; */
  padding-top: 2rem;
  text-align: right;
  font-family: "Staatliches";
  font-size: 24px;
}

.submit-button{
  width: 210px;
  height: 45px;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  color: black;
  /* filter: grayscale(100%) brightness(1.5) contrast(.7);
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out; */
}
.submit-button:hover{
  width: 210px;
  height: 45px;
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  /* filter: grayscale(0) brightness(1) contrast(1);
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out; */
}


/* ------------- Timeline ----------------- */
.timeline{
  -webkit-transform: rotate(90deg);
  top: 50%;
  left: -27vmin;
  position: fixed;
  z-index: 25;
  width: 60vh;
}

.timelineBox{
  /* align-self: center;
  align-items: center; */
  width:  100vw;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 10px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 9999;
}
progress {
  border-radius: 1px; 
  height: 6px;
  /* box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 ); */
  
}
progress::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.2 );
  border-radius: 1px;
}
progress::-webkit-progress-value {
  background-color: rgba(58, 1, 34, 0.8);
  border-radius: 1px;
  box-shadow: 1px 1px 3px 2px rgb(239, 225, 80);
}
progress::-moz-progress-bar {
  /* style rules */
}



/* -_-_-_----__------___-------------- */

/* ------------- Team -------------------- */
.teamMembers{
  height: 170vh;
  width: 100%;
}
.team-member-meteor{
  position: absolute;
  /* padding-top: 10px; */
  /* transform: translate3d(50%, 0, 0); */
  text-align: left;
  background: rgba(20, 20, 20,0);
  color: white;
  /* padding: 10px 15px; */
  border-radius: 5px;
  /* padding-right: 20px; */
}

.team-card{
  height: 500x;
  width: 500px;
  filter: grayscale(0) brightness(1.5) contrast(.7);
  padding: 10%;
  transform: scale(.98) ;
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
}

.team-card:hover {
  filter: grayscale(0%) brightness(1) contrast(1);
  transform: scale(1);
  cursor: pointer;
}


/* --------------- Partners ---------------- */
/* .partner-item{
  color: white;
  text-align: center;
  height: 100px;
  padding: 25px;
  border: 1px solid grey;
}

.partner-item-mobile{
  color: white;
  text-align: center;
  height: 100px;
  padding: 25px;
  border: 1px solid grey;
}

.partner-item-img{
  width: 125px;
  height: 75px;
  align-items:center;
  text-align: center;
} */

.canvasPartners{
  position: relative !important;
  width:  100vw !important;
  height: 800px !important; 
  
}

.coursel-root{

  position: relative !important;
}
.partnersTitle{
  padding-top: 50px !important;
}
.legend{
  background: transparent !important;
}


@media only screen and (max-width: 780px) {
  .canvasPartners{ 
      position: relative;
      width:  100vw !important;
      height: 500px !important; 
  }
}

.partners-title {
  text-align: center;
  margin: 50px auto 0;
  width: 720px;
}
.partners-title__h2 {
  font-size:45px;
  line-height:52px;
  margin-bottom: 30px;
  font-weight: 700;
}

.partners-title__citat {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 30px;
  font-weight: 400;
}

.partners-title__p {
  font-size: 23px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 30px;
}

/*odavde jedinstven css*/

.partners-container img {
  width: 100%;
  }

.ss-partners {
  max-width: 1200px;
  margin: 0 auto;
}

.partners-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  flex-direction: row;
}

.partner-card-container {
  flex: 0 0 20%; /*ovde! izmeniti za vise partnera*/
  max-width: 33%; /*ovde! izmeniti za vise partnera*/

  display: flex;
  align-items: center;
  justify-content: center;
}
.small {
  display: none;
}
.partner-logo {
  height: 125px;
  filter: grayscale(100%) brightness(1.5) contrast(.7);
  padding: 10%;
  transform: scale(.98) ;
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
}

.partner-logo.small {
  filter: grayscale(100%) brightness(1.5) contrast(.7);
  padding: 5%;
  transform: scale(.7) ;
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
}

.partner-logo:hover {
  filter: grayscale(0%) brightness(1) contrast(1);
  transform: scale(1);
  cursor: pointer;
}


.partner-logo-brandups {
  height: 125px;
  filter: grayscale(0) brightness(1.5) contrast(.7);
  padding: 10%;
  transform: scale(.98) ;
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
}

.partner-logo-brandups.small {
  filter: grayscale(0) brightness(1.5) contrast(.7);
  padding: 5%;
  transform: scale(.7) ;
  transition: filter .4s ease-in-out,
              transform .4s ease-in-out;
}

.partner-logo-brandups:hover {
  filter: grayscale(0) brightness(1) contrast(1);
  transform: scale(1);
  cursor: pointer;
}

@media only screen and (max-width: 780px) {
  .partner-card-container {
      flex: 0 0 30%;
      max-width: 50%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      }
}
@media only screen and (max-width: 780px) {
  .partner-logo {
    height: 95px;
    filter: grayscale(100%) brightness(1.5) contrast(.7);
    padding: 10%;
    transform: scale(.98) ;
    transition: filter .4s ease-in-out,
                transform .4s ease-in-out;
  }
  
}
@media only screen and (max-width: 320px) {
  .small {
      display: block;
  }
  .big {
      display: none;
  }
  .partner-card-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      margin: 0 auto;
      }
}

/* OVO NE - VEZANO ZA NASLOV */

@media only screen and (max-width: 768px) {
  .partners-title {
      width: 540px;
  }
}
@media only screen and (max-width: 320px) {
  .partners-title {
      width: 272px;
  }
}
@media only screen and (max-width: 480px) {
  .partners-title {
      width: 332px;
  }
  .partners-title__h2 {
      font: size 32px;
      line-height:36px;
      margin-bottom: 30px;
      font-weight: 700;
  }    
  .partners-title__p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 0;
      margin-bottom: 30px;
  }
  .partners-title__citat {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 30px;
      font-weight: 400;
  }
}


/* BottomSheet */


.bottomSheetContent{
  font-size: 14px;
  border: 1px solid rgb(151, 151, 151, 0.3);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 10px 15px rgb(151, 151, 151, 0.4);
}

@media screen and (max-width: 560px) {
  .bottomSheetContent{
    font-size: 11px;
    border: 1px solid rgb(151, 151, 151, 0.1);
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 10px 15px rgb(151, 151, 151, 0.4);
}
}

.bottomSheetCard{
  width: 70%;
  align-items: center;
  align-self: center;
  font-family: 'Courier New', Courier, monospace;
}

@media screen and (max-width: 560px) {
  .bottomSheetCard{
    width: 100%;
    align-items: center;
    align-self: center;
    font-family: 'Courier New', Courier, monospace;
  }
}

/* Team Cards */

/* .flip-card-container */

.flip-card-container {
  /* --hue: 150; */
  /* --primary: hsl(var(--hue), 50%, 50%);
  --white-1: hsl(0, 0%, 90%);
  --white-2: hsl(0, 0%, 80%);
  --dark: hsl(var(--hue), 25%, 10%);
  --grey: hsl(0, 0%, 50%); */

background-color: transparent;
border: 0px solid transparent;
  width: 360px;
  height: 650px;
  margin: 40px;

  /* perspective: 1000px; */
}


@media screen and (max-width: 560px) {
  .flip-card-container {
    /* --hue: 150; */
    /* --primary: hsl(var(--hue), 50%, 50%);
    --white-1: hsl(0, 0%, 90%);
    --white-2: hsl(0, 0%, 80%);
    --dark: hsl(var(--hue), 25%, 10%);
    --grey: hsl(0, 0%, 50%); */
    width: 310px;
    height: 600px;
  
    /* perspective: 1000px; */
  }
}




/* .flip-card */
.flip-card {
  width: inherit;
  height: inherit;

  position: relative;
  transform-style: preserve-3d;
  transition: .6s .1s;
}

/* hover and focus-within states */
.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
  transform: rotateY(180deg);
}

/* .card-... */
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  border-radius: 24px;

  background: var(--dark);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card-front */
.card-front {
  transform: rotateY(0deg);
  z-index: 2;
}

/* .card-back */
.card-back {
  transform: rotateY(180deg);
  z-index: 1;
}

/* figure */
figure {
  z-index: -1;
}

/* figure, .img-bg */
figure,
.img-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

/* img */
.img-team-card {
  height: 100%;
  width: 100%;
  border-radius: 24px;
}

/* figcaption */
figcaption {
  display: block;

  width: auto;
  margin-top: 12%;
  padding: 8px 22px;

  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 2px;
  word-spacing: 6px;
  text-align: right;

  position: absolute;
  top: 0;
  right: 12px;

  color: var(--white-1);
  background: hsla(var(--hue), 25%, 10%, .5);
}

/* .img-bg */
.img-bg {
  background: hsla(var(--hue), 25%, 10%, .5);
}

.card-front .img-bg {
  clip-path: polygon(0 20%, 100% 40%, 100% 100%, 0 100%);
}

.card-front .img-bg::before {
  content: "";

  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(18deg);

  width: 100%;
  height: 6px;
  border: 1px solid var(--primary);
  border-left-color: transparent;
  border-right-color: transparent;

  transition: .1s;
}

.card-back .img-bg {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
}

/* hover state */
.flip-card-container:hover .card-front .img-bg::before {
  width: 6px;
  border-left-color: var(--primary);
  border-right-color: var(--primary);
}

/* ul */
.flip-card-container ul {
  padding-top: 50%;
  margin: 0 auto;
  width: 70%;
  height: 100%;

  list-style: none;
  color: var(--white-1);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* li */
.flip-card-container li {
  width: 100%;
  margin-top: 12px;
  padding-bottom: 12px;

  font-size: 14px;
  text-align: center;

  position: relative;
}

.flip-card-container li:nth-child(2n) {
  color: var(--white-2);
}

.flip-card-container li:not(:last-child)::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;

  background: currentColor;
  opacity: .2;
}

/* button */
.flip-card-container button {
  font-family: inherit;
  font-weight: bold;
  color: var(--white-1);

  letter-spacing: 2px;

  padding: 9px 20px;
  border: 1px solid var(--grey);
  border-radius: 1000px;
  background: transparent;
  transition: .3s;

  cursor: pointer;
}

.flip-card-container button:hover,
.flip-card-container button:focus {
  color: var(--primary);
  background: hsla(var(--hue), 25%, 10%, .2);
  border-color: currentColor;
}

.flip-card-container button:active {
  transform: translate(2px);
}

/* .design-container */
.design-container {
  --tr: 90;
  --op: .5;

  width: 100%;
  height: 100%;

  background: transparent;
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
}

/* .design */
.design {
  display: block;

  background: var(--grey);
  position: absolute;

  opacity: var(--op);
  transition: .3s;
}

.design--1,
.design--2,
.design--3,
.design--4 {
  width: 1px;
  height: 100%;
}

.design--1,
.design--2 {
  top: 0;
  transform: translateY(calc((var(--tr) - (var(--tr) * 2)) * 1%))
}

.design--1 {
  left: 20%;
}

.design--2 {
  left: 80%;
}

.design--3,
.design--4 {
  bottom: 0;
  transform: translateY(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%))
}

.design--3 {
  left: 24%;
}

.design--4 {
  left: 76%;
}

.design--5,
.design--6,
.design--7,
.design--8 {
  width: 100%;
  height: 1px;
}

.design--5,
.design--6 {
  left: 0;
  transform: translateX(calc((var(--tr) - (var(--tr) * 2)) * 1%));
}

.design--5 {
  top: 41%;
}

.design--6 {
  top: 59%;
}

.design--7,
.design--8 {
  right: 0;
  transform: translateX(calc((var(--tr) + (var(--tr) - var(--tr))) * 1%))
}

.design--7 {
  top: 44%;
}

.design--8 {
  top: 56%;
}

/* states */
button:hover+.design-container,
button:active+.design-container,
button:focus+.design-container {
  --tr: 20;
  --op: .7;
}

.abs-site-link {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: hsla(0, 0%, 100%, .6);
  font-size: 16px;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
}




/* ------------- FireFly ------------- */

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none; }
  .firefly::before, .firefly::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw; }
  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite; }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite; }

.firefly:nth-child(1) {
  animation-name: move1; }
  .firefly:nth-child(1)::before {
    animation-duration: 10s; }
  .firefly:nth-child(1)::after {
    animation-duration: 10s, 6255ms;
    animation-delay: 0ms, 1752ms; }

@keyframes move1 {
  0% {
    transform: translateX(4vw) translateY(35vh) scale(0.52); }
  3.57143% {
    transform: translateX(17vw) translateY(-41vh) scale(0.56); }
  7.14286% {
    transform: translateX(-40vw) translateY(-41vh) scale(0.53); }
  10.71429% {
    transform: translateX(-5vw) translateY(30vh) scale(0.57); }
  14.28571% {
    transform: translateX(-30vw) translateY(32vh) scale(0.43); }
  17.85714% {
    transform: translateX(42vw) translateY(15vh) scale(0.55); }
  21.42857% {
    transform: translateX(-24vw) translateY(-18vh) scale(0.69); }
  25% {
    transform: translateX(40vw) translateY(50vh) scale(0.94); }
  28.57143% {
    transform: translateX(7vw) translateY(8vh) scale(0.38); }
  32.14286% {
    transform: translateX(-41vw) translateY(34vh) scale(0.54); }
  35.71429% {
    transform: translateX(-31vw) translateY(16vh) scale(0.29); }
  39.28571% {
    transform: translateX(47vw) translateY(-24vh) scale(0.91); }
  42.85714% {
    transform: translateX(-42vw) translateY(19vh) scale(0.51); }
  46.42857% {
    transform: translateX(-4vw) translateY(22vh) scale(0.68); }
  50% {
    transform: translateX(36vw) translateY(-38vh) scale(0.7); }
  53.57143% {
    transform: translateX(12vw) translateY(38vh) scale(0.4); }
  57.14286% {
    transform: translateX(31vw) translateY(-34vh) scale(0.46); }
  60.71429% {
    transform: translateX(21vw) translateY(-16vh) scale(0.93); }
  64.28571% {
    transform: translateX(41vw) translateY(17vh) scale(0.66); }
  67.85714% {
    transform: translateX(-34vw) translateY(-49vh) scale(0.7); }
  71.42857% {
    transform: translateX(8vw) translateY(-46vh) scale(0.46); }
  75% {
    transform: translateX(18vw) translateY(44vh) scale(0.34); }
  78.57143% {
    transform: translateX(38vw) translateY(-46vh) scale(0.98); }
  82.14286% {
    transform: translateX(24vw) translateY(-28vh) scale(0.95); }
  85.71429% {
    transform: translateX(-22vw) translateY(21vh) scale(0.67); }
  89.28571% {
    transform: translateX(29vw) translateY(6vh) scale(0.52); }
  92.85714% {
    transform: translateX(16vw) translateY(-42vh) scale(0.52); }
  96.42857% {
    transform: translateX(13vw) translateY(-24vh) scale(0.8); }
  100% {
    transform: translateX(5vw) translateY(-4vh) scale(0.35); } }

.firefly:nth-child(2) {
  animation-name: move2; }
  .firefly:nth-child(2)::before {
    animation-duration: 15s; }
  .firefly:nth-child(2)::after {
    animation-duration: 15s, 7203ms;
    animation-delay: 0ms, 3039ms; }

@keyframes move2 {
  0% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.89); }
  4.16667% {
    transform: translateX(-38vw) translateY(35vh) scale(0.73); }
  8.33333% {
    transform: translateX(15vw) translateY(-48vh) scale(0.65); }
  12.5% {
    transform: translateX(8vw) translateY(-3vh) scale(0.26); }
  16.66667% {
    transform: translateX(-22vw) translateY(-43vh) scale(0.96); }
  20.83333% {
    transform: translateX(8vw) translateY(3vh) scale(0.88); }
  25% {
    transform: translateX(3vw) translateY(-34vh) scale(0.44); }
  29.16667% {
    transform: translateX(-36vw) translateY(10vh) scale(0.46); }
  33.33333% {
    transform: translateX(-47vw) translateY(-40vh) scale(0.77); }
  37.5% {
    transform: translateX(2vw) translateY(6vh) scale(0.58); }
  41.66667% {
    transform: translateX(30vw) translateY(45vh) scale(0.86); }
  45.83333% {
    transform: translateX(42vw) translateY(36vh) scale(0.79); }
  50% {
    transform: translateX(22vw) translateY(29vh) scale(0.67); }
  54.16667% {
    transform: translateX(-34vw) translateY(-24vh) scale(0.75); }
  58.33333% {
    transform: translateX(31vw) translateY(47vh) scale(0.78); }
  62.5% {
    transform: translateX(-21vw) translateY(-29vh) scale(0.57); }
  66.66667% {
    transform: translateX(-25vw) translateY(6vh) scale(0.32); }
  70.83333% {
    transform: translateX(7vw) translateY(41vh) scale(0.39); }
  75% {
    transform: translateX(-36vw) translateY(-42vh) scale(0.78); }
  79.16667% {
    transform: translateX(-44vw) translateY(16vh) scale(0.93); }
  83.33333% {
    transform: translateX(44vw) translateY(-2vh) scale(0.4); }
  87.5% {
    transform: translateX(18vw) translateY(28vh) scale(0.38); }
  91.66667% {
    transform: translateX(-1vw) translateY(46vh) scale(0.83); }
  95.83333% {
    transform: translateX(6vw) translateY(-28vh) scale(0.51); }
  100% {
    transform: translateX(-3vw) translateY(-44vh) scale(0.63); } }

.firefly:nth-child(3) {
  animation-name: move3; }
  .firefly:nth-child(3)::before {
    animation-duration: 12s; }
  .firefly:nth-child(3)::after {
    animation-duration: 12s, 5673ms;
    animation-delay: 0ms, 4786ms; }

@keyframes move3 {
  0% {
    transform: translateX(-28vw) translateY(-24vh) scale(0.6); }
  4.7619% {
    transform: translateX(24vw) translateY(-43vh) scale(0.37); }
  9.52381% {
    transform: translateX(-46vw) translateY(28vh) scale(0.57); }
  14.28571% {
    transform: translateX(-25vw) translateY(42vh) scale(0.54); }
  19.04762% {
    transform: translateX(-3vw) translateY(-46vh) scale(0.54); }
  23.80952% {
    transform: translateX(-32vw) translateY(37vh) scale(0.66); }
  28.57143% {
    transform: translateX(40vw) translateY(-38vh) scale(0.6); }
  33.33333% {
    transform: translateX(0vw) translateY(28vh) scale(0.75); }
  38.09524% {
    transform: translateX(-27vw) translateY(-28vh) scale(0.67); }
  42.85714% {
    transform: translateX(35vw) translateY(-8vh) scale(0.27); }
  47.61905% {
    transform: translateX(-4vw) translateY(-49vh) scale(0.67); }
  52.38095% {
    transform: translateX(37vw) translateY(23vh) scale(0.64); }
  57.14286% {
    transform: translateX(44vw) translateY(-33vh) scale(0.41); }
  61.90476% {
    transform: translateX(-7vw) translateY(-14vh) scale(0.67); }
  66.66667% {
    transform: translateX(45vw) translateY(12vh) scale(0.78); }
  71.42857% {
    transform: translateX(11vw) translateY(-7vh) scale(0.84); }
  76.19048% {
    transform: translateX(-33vw) translateY(-36vh) scale(0.57); }
  80.95238% {
    transform: translateX(33vw) translateY(-46vh) scale(0.78); }
  85.71429% {
    transform: translateX(-17vw) translateY(7vh) scale(0.31); }
  90.47619% {
    transform: translateX(-23vw) translateY(-49vh) scale(0.96); }
  95.2381% {
    transform: translateX(1vw) translateY(18vh) scale(0.75); }
  100% {
    transform: translateX(18vw) translateY(-36vh) scale(0.96); } }

.firefly:nth-child(4) {
  animation-name: move4; }
  .firefly:nth-child(4)::before {
    animation-duration: 9s; }
  .firefly:nth-child(4)::after {
    animation-duration: 9s, 5985ms;
    animation-delay: 0ms, 4746ms; }

@keyframes move4 {
  0% {
    transform: translateX(2vw) translateY(-6vh) scale(0.96); }
  4.54545% {
    transform: translateX(27vw) translateY(15vh) scale(0.88); }
  9.09091% {
    transform: translateX(-4vw) translateY(-27vh) scale(0.61); }
  13.63636% {
    transform: translateX(-27vw) translateY(43vh) scale(0.4); }
  18.18182% {
    transform: translateX(32vw) translateY(21vh) scale(0.74); }
  22.72727% {
    transform: translateX(5vw) translateY(19vh) scale(0.94); }
  27.27273% {
    transform: translateX(28vw) translateY(-32vh) scale(0.5); }
  31.81818% {
    transform: translateX(17vw) translateY(16vh) scale(0.53); }
  36.36364% {
    transform: translateX(4vw) translateY(-21vh) scale(0.32); }
  40.90909% {
    transform: translateX(-8vw) translateY(44vh) scale(0.57); }
  45.45455% {
    transform: translateX(-22vw) translateY(-2vh) scale(0.79); }
  50% {
    transform: translateX(-7vw) translateY(4vh) scale(0.64); }
  54.54545% {
    transform: translateX(-26vw) translateY(-14vh) scale(0.8); }
  59.09091% {
    transform: translateX(-5vw) translateY(21vh) scale(0.95); }
  63.63636% {
    transform: translateX(-24vw) translateY(-22vh) scale(0.74); }
  68.18182% {
    transform: translateX(17vw) translateY(-30vh) scale(0.32); }
  72.72727% {
    transform: translateX(-16vw) translateY(13vh) scale(0.72); }
  77.27273% {
    transform: translateX(-38vw) translateY(29vh) scale(0.89); }
  81.81818% {
    transform: translateX(48vw) translateY(4vh) scale(0.79); }
  86.36364% {
    transform: translateX(18vw) translateY(-48vh) scale(0.68); }
  90.90909% {
    transform: translateX(-25vw) translateY(1vh) scale(0.96); }
  95.45455% {
    transform: translateX(-24vw) translateY(10vh) scale(0.75); }
  100% {
    transform: translateX(9vw) translateY(-27vh) scale(0.7); } }

.firefly:nth-child(5) {
  animation-name: move5; }
  .firefly:nth-child(5)::before {
    animation-duration: 17s; }
  .firefly:nth-child(5)::after {
    animation-duration: 17s, 5802ms;
    animation-delay: 0ms, 4434ms; }

@keyframes move5 {
  0% {
    transform: translateX(-5vw) translateY(-13vh) scale(0.66); }
  3.57143% {
    transform: translateX(11vw) translateY(-22vh) scale(0.49); }
  7.14286% {
    transform: translateX(-26vw) translateY(37vh) scale(0.35); }
  10.71429% {
    transform: translateX(-20vw) translateY(30vh) scale(0.39); }
  14.28571% {
    transform: translateX(-27vw) translateY(13vh) scale(0.84); }
  17.85714% {
    transform: translateX(28vw) translateY(31vh) scale(0.62); }
  21.42857% {
    transform: translateX(17vw) translateY(-48vh) scale(0.88); }
  25% {
    transform: translateX(27vw) translateY(-22vh) scale(0.58); }
  28.57143% {
    transform: translateX(-5vw) translateY(-15vh) scale(0.8); }
  32.14286% {
    transform: translateX(38vw) translateY(7vh) scale(0.94); }
  35.71429% {
    transform: translateX(15vw) translateY(-5vh) scale(0.55); }
  39.28571% {
    transform: translateX(-15vw) translateY(-7vh) scale(0.57); }
  42.85714% {
    transform: translateX(-19vw) translateY(-21vh) scale(0.72); }
  46.42857% {
    transform: translateX(1vw) translateY(42vh) scale(0.81); }
  50% {
    transform: translateX(-33vw) translateY(-48vh) scale(0.89); }
  53.57143% {
    transform: translateX(-23vw) translateY(13vh) scale(0.32); }
  57.14286% {
    transform: translateX(46vw) translateY(-7vh) scale(0.87); }
  60.71429% {
    transform: translateX(-47vw) translateY(-40vh) scale(0.34); }
  64.28571% {
    transform: translateX(-47vw) translateY(-32vh) scale(0.48); }
  67.85714% {
    transform: translateX(29vw) translateY(-16vh) scale(0.93); }
  71.42857% {
    transform: translateX(-19vw) translateY(17vh) scale(0.57); }
  75% {
    transform: translateX(9vw) translateY(31vh) scale(0.59); }
  78.57143% {
    transform: translateX(21vw) translateY(41vh) scale(0.85); }
  82.14286% {
    transform: translateX(2vw) translateY(-23vh) scale(0.87); }
  85.71429% {
    transform: translateX(19vw) translateY(18vh) scale(0.83); }
  89.28571% {
    transform: translateX(-9vw) translateY(45vh) scale(0.34); }
  92.85714% {
    transform: translateX(7vw) translateY(-1vh) scale(0.89); }
  96.42857% {
    transform: translateX(-32vw) translateY(-47vh) scale(0.73); }
  100% {
    transform: translateX(-48vw) translateY(-6vh) scale(0.98); } }

.firefly:nth-child(6) {
  animation-name: move6; }
  .firefly:nth-child(6)::before {
    animation-duration: 16s; }
  .firefly:nth-child(6)::after {
    animation-duration: 16s, 10219ms;
    animation-delay: 0ms, 4179ms; }

@keyframes move6 {
  0% {
    transform: translateX(26vw) translateY(49vh) scale(0.34); }
  3.7037% {
    transform: translateX(-7vw) translateY(-40vh) scale(0.35); }
  7.40741% {
    transform: translateX(-28vw) translateY(25vh) scale(0.47); }
  11.11111% {
    transform: translateX(27vw) translateY(10vh) scale(0.52); }
  14.81481% {
    transform: translateX(31vw) translateY(-23vh) scale(0.87); }
  18.51852% {
    transform: translateX(-13vw) translateY(-5vh) scale(0.92); }
  22.22222% {
    transform: translateX(-21vw) translateY(35vh) scale(0.93); }
  25.92593% {
    transform: translateX(31vw) translateY(32vh) scale(0.97); }
  29.62963% {
    transform: translateX(44vw) translateY(27vh) scale(0.34); }
  33.33333% {
    transform: translateX(-15vw) translateY(6vh) scale(0.9); }
  37.03704% {
    transform: translateX(40vw) translateY(11vh) scale(0.58); }
  40.74074% {
    transform: translateX(-8vw) translateY(35vh) scale(0.33); }
  44.44444% {
    transform: translateX(-40vw) translateY(-46vh) scale(0.56); }
  48.14815% {
    transform: translateX(-15vw) translateY(-4vh) scale(0.44); }
  51.85185% {
    transform: translateX(-49vw) translateY(-43vh) scale(1); }
  55.55556% {
    transform: translateX(39vw) translateY(49vh) scale(0.28); }
  59.25926% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.85); }
  62.96296% {
    transform: translateX(-40vw) translateY(42vh) scale(0.71); }
  66.66667% {
    transform: translateX(38vw) translateY(26vh) scale(0.34); }
  70.37037% {
    transform: translateX(31vw) translateY(5vh) scale(0.47); }
  74.07407% {
    transform: translateX(18vw) translateY(-7vh) scale(0.74); }
  77.77778% {
    transform: translateX(39vw) translateY(-38vh) scale(0.81); }
  81.48148% {
    transform: translateX(48vw) translateY(-35vh) scale(0.88); }
  85.18519% {
    transform: translateX(47vw) translateY(-9vh) scale(0.57); }
  88.88889% {
    transform: translateX(3vw) translateY(-35vh) scale(0.77); }
  92.59259% {
    transform: translateX(43vw) translateY(-10vh) scale(0.48); }
  96.2963% {
    transform: translateX(15vw) translateY(-22vh) scale(0.42); }
  100% {
    transform: translateX(49vw) translateY(-28vh) scale(0.76); } }

.firefly:nth-child(7) {
  animation-name: move7; }
  .firefly:nth-child(7)::before {
    animation-duration: 14s; }
  .firefly:nth-child(7)::after {
    animation-duration: 14s, 8644ms;
    animation-delay: 0ms, 7908ms; }

@keyframes move7 {
  0% {
    transform: translateX(7vw) translateY(28vh) scale(0.85); }
  5% {
    transform: translateX(49vw) translateY(0vh) scale(0.39); }
  10% {
    transform: translateX(38vw) translateY(3vh) scale(0.48); }
  15% {
    transform: translateX(11vw) translateY(-16vh) scale(0.47); }
  20% {
    transform: translateX(11vw) translateY(-35vh) scale(0.56); }
  25% {
    transform: translateX(-3vw) translateY(-31vh) scale(0.49); }
  30% {
    transform: translateX(-14vw) translateY(21vh) scale(0.77); }
  35% {
    transform: translateX(-41vw) translateY(-6vh) scale(0.9); }
  40% {
    transform: translateX(-16vw) translateY(-37vh) scale(0.72); }
  45% {
    transform: translateX(11vw) translateY(34vh) scale(0.77); }
  50% {
    transform: translateX(46vw) translateY(-32vh) scale(0.59); }
  55% {
    transform: translateX(-19vw) translateY(-3vh) scale(0.94); }
  60% {
    transform: translateX(-30vw) translateY(-44vh) scale(0.78); }
  65% {
    transform: translateX(12vw) translateY(-31vh) scale(0.62); }
  70% {
    transform: translateX(31vw) translateY(5vh) scale(0.35); }
  75% {
    transform: translateX(-29vw) translateY(-38vh) scale(0.56); }
  80% {
    transform: translateX(-3vw) translateY(12vh) scale(0.97); }
  85% {
    transform: translateX(-3vw) translateY(-39vh) scale(0.32); }
  90% {
    transform: translateX(-5vw) translateY(24vh) scale(0.42); }
  95% {
    transform: translateX(-27vw) translateY(44vh) scale(0.28); }
  100% {
    transform: translateX(16vw) translateY(10vh) scale(0.4); } }

.firefly:nth-child(8) {
  animation-name: move8; }
  .firefly:nth-child(8)::before {
    animation-duration: 16s; }
  .firefly:nth-child(8)::after {
    animation-duration: 16s, 9601ms;
    animation-delay: 0ms, 6955ms; }

@keyframes move8 {
  0% {
    transform: translateX(7vw) translateY(-28vh) scale(0.67); }
  5.55556% {
    transform: translateX(-14vw) translateY(25vh) scale(0.64); }
  11.11111% {
    transform: translateX(4vw) translateY(33vh) scale(0.37); }
  16.66667% {
    transform: translateX(18vw) translateY(-37vh) scale(0.72); }
  22.22222% {
    transform: translateX(6vw) translateY(-43vh) scale(0.96); }
  27.77778% {
    transform: translateX(0vw) translateY(-27vh) scale(0.37); }
  33.33333% {
    transform: translateX(7vw) translateY(26vh) scale(0.63); }
  38.88889% {
    transform: translateX(33vw) translateY(-24vh) scale(0.57); }
  44.44444% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.89); }
  50% {
    transform: translateX(-23vw) translateY(-45vh) scale(0.53); }
  55.55556% {
    transform: translateX(-14vw) translateY(-15vh) scale(0.95); }
  61.11111% {
    transform: translateX(-35vw) translateY(-43vh) scale(0.76); }
  66.66667% {
    transform: translateX(1vw) translateY(-24vh) scale(0.83); }
  72.22222% {
    transform: translateX(-20vw) translateY(37vh) scale(0.87); }
  77.77778% {
    transform: translateX(20vw) translateY(-24vh) scale(0.59); }
  83.33333% {
    transform: translateX(22vw) translateY(24vh) scale(0.79); }
  88.88889% {
    transform: translateX(33vw) translateY(-46vh) scale(0.97); }
  94.44444% {
    transform: translateX(-18vw) translateY(3vh) scale(0.66); }
  100% {
    transform: translateX(7vw) translateY(-49vh) scale(0.91); } }

.firefly:nth-child(9) {
  animation-name: move9; }
  .firefly:nth-child(9)::before {
    animation-duration: 18s; }
  .firefly:nth-child(9)::after {
    animation-duration: 18s, 5482ms;
    animation-delay: 0ms, 4153ms; }

@keyframes move9 {
  0% {
    transform: translateX(38vw) translateY(-8vh) scale(0.62); }
  3.57143% {
    transform: translateX(14vw) translateY(-43vh) scale(0.91); }
  7.14286% {
    transform: translateX(49vw) translateY(4vh) scale(0.55); }
  10.71429% {
    transform: translateX(-27vw) translateY(-24vh) scale(0.26); }
  14.28571% {
    transform: translateX(-32vw) translateY(15vh) scale(0.78); }
  17.85714% {
    transform: translateX(41vw) translateY(40vh) scale(0.87); }
  21.42857% {
    transform: translateX(12vw) translateY(-4vh) scale(0.36); }
  25% {
    transform: translateX(-12vw) translateY(-40vh) scale(0.37); }
  28.57143% {
    transform: translateX(-27vw) translateY(30vh) scale(0.71); }
  32.14286% {
    transform: translateX(-17vw) translateY(39vh) scale(0.44); }
  35.71429% {
    transform: translateX(27vw) translateY(30vh) scale(0.88); }
  39.28571% {
    transform: translateX(-30vw) translateY(-16vh) scale(0.95); }
  42.85714% {
    transform: translateX(7vw) translateY(11vh) scale(0.38); }
  46.42857% {
    transform: translateX(-29vw) translateY(16vh) scale(0.27); }
  50% {
    transform: translateX(-47vw) translateY(40vh) scale(0.35); }
  53.57143% {
    transform: translateX(32vw) translateY(15vh) scale(0.57); }
  57.14286% {
    transform: translateX(21vw) translateY(30vh) scale(0.86); }
  60.71429% {
    transform: translateX(8vw) translateY(36vh) scale(0.81); }
  64.28571% {
    transform: translateX(11vw) translateY(-12vh) scale(0.33); }
  67.85714% {
    transform: translateX(21vw) translateY(45vh) scale(0.92); }
  71.42857% {
    transform: translateX(-12vw) translateY(-28vh) scale(0.56); }
  75% {
    transform: translateX(5vw) translateY(30vh) scale(0.67); }
  78.57143% {
    transform: translateX(-46vw) translateY(25vh) scale(0.93); }
  82.14286% {
    transform: translateX(-47vw) translateY(41vh) scale(0.44); }
  85.71429% {
    transform: translateX(-28vw) translateY(21vh) scale(0.28); }
  89.28571% {
    transform: translateX(-7vw) translateY(-29vh) scale(0.43); }
  92.85714% {
    transform: translateX(-23vw) translateY(-22vh) scale(0.77); }
  96.42857% {
    transform: translateX(-28vw) translateY(-27vh) scale(0.94); }
  100% {
    transform: translateX(47vw) translateY(29vh) scale(0.49); } }

.firefly:nth-child(10) {
  animation-name: move10; }
  .firefly:nth-child(10)::before {
    animation-duration: 11s; }
  .firefly:nth-child(10)::after {
    animation-duration: 11s, 10884ms;
    animation-delay: 0ms, 5264ms; }

@keyframes move10 {
  0% {
    transform: translateX(1vw) translateY(12vh) scale(0.82); }
  5.55556% {
    transform: translateX(31vw) translateY(26vh) scale(0.67); }
  11.11111% {
    transform: translateX(-49vw) translateY(-41vh) scale(0.93); }
  16.66667% {
    transform: translateX(15vw) translateY(5vh) scale(0.47); }
  22.22222% {
    transform: translateX(40vw) translateY(44vh) scale(0.76); }
  27.77778% {
    transform: translateX(17vw) translateY(-10vh) scale(0.41); }
  33.33333% {
    transform: translateX(-11vw) translateY(49vh) scale(0.63); }
  38.88889% {
    transform: translateX(15vw) translateY(21vh) scale(0.84); }
  44.44444% {
    transform: translateX(-25vw) translateY(22vh) scale(0.96); }
  50% {
    transform: translateX(40vw) translateY(-31vh) scale(0.9); }
  55.55556% {
    transform: translateX(-20vw) translateY(19vh) scale(0.75); }
  61.11111% {
    transform: translateX(-13vw) translateY(-18vh) scale(0.48); }
  66.66667% {
    transform: translateX(50vw) translateY(17vh) scale(0.98); }
  72.22222% {
    transform: translateX(-22vw) translateY(-3vh) scale(0.53); }
  77.77778% {
    transform: translateX(30vw) translateY(24vh) scale(0.79); }
  83.33333% {
    transform: translateX(-31vw) translateY(15vh) scale(0.5); }
  88.88889% {
    transform: translateX(-37vw) translateY(9vh) scale(0.62); }
  94.44444% {
    transform: translateX(-42vw) translateY(44vh) scale(0.55); }
  100% {
    transform: translateX(-42vw) translateY(-37vh) scale(0.35); } }

.firefly:nth-child(11) {
  animation-name: move11; }
  .firefly:nth-child(11)::before {
    animation-duration: 18s; }
  .firefly:nth-child(11)::after {
    animation-duration: 18s, 9632ms;
    animation-delay: 0ms, 4348ms; }

@keyframes move11 {
  0% {
    transform: translateX(-28vw) translateY(-17vh) scale(0.7); }
  4% {
    transform: translateX(-27vw) translateY(-38vh) scale(0.77); }
  8% {
    transform: translateX(-2vw) translateY(45vh) scale(0.82); }
  12% {
    transform: translateX(7vw) translateY(-11vh) scale(0.72); }
  16% {
    transform: translateX(-26vw) translateY(-46vh) scale(0.92); }
  20% {
    transform: translateX(18vw) translateY(11vh) scale(0.52); }
  24% {
    transform: translateX(-16vw) translateY(-39vh) scale(0.92); }
  28% {
    transform: translateX(50vw) translateY(-15vh) scale(0.98); }
  32% {
    transform: translateX(4vw) translateY(-23vh) scale(0.37); }
  36% {
    transform: translateX(8vw) translateY(-16vh) scale(0.98); }
  40% {
    transform: translateX(21vw) translateY(-8vh) scale(0.92); }
  44% {
    transform: translateX(-45vw) translateY(-28vh) scale(0.88); }
  48% {
    transform: translateX(-26vw) translateY(-39vh) scale(0.7); }
  52% {
    transform: translateX(21vw) translateY(-39vh) scale(1); }
  56% {
    transform: translateX(37vw) translateY(-35vh) scale(0.43); }
  60% {
    transform: translateX(-31vw) translateY(34vh) scale(0.6); }
  64% {
    transform: translateX(-8vw) translateY(-14vh) scale(0.49); }
  68% {
    transform: translateX(-2vw) translateY(44vh) scale(0.28); }
  72% {
    transform: translateX(36vw) translateY(-28vh) scale(0.45); }
  76% {
    transform: translateX(48vw) translateY(-28vh) scale(0.54); }
  80% {
    transform: translateX(-6vw) translateY(-1vh) scale(0.94); }
  84% {
    transform: translateX(0vw) translateY(-45vh) scale(0.99); }
  88% {
    transform: translateX(0vw) translateY(24vh) scale(0.66); }
  92% {
    transform: translateX(45vw) translateY(10vh) scale(0.29); }
  96% {
    transform: translateX(15vw) translateY(-12vh) scale(0.37); }
  100% {
    transform: translateX(42vw) translateY(-11vh) scale(0.62); } }

.firefly:nth-child(12) {
  animation-name: move12; }
  .firefly:nth-child(12)::before {
    animation-duration: 13s; }
  .firefly:nth-child(12)::after {
    animation-duration: 13s, 9843ms;
    animation-delay: 0ms, 5196ms; }

@keyframes move12 {
  0% {
    transform: translateX(-46vw) translateY(-11vh) scale(0.97); }
  3.57143% {
    transform: translateX(-29vw) translateY(10vh) scale(0.97); }
  7.14286% {
    transform: translateX(-7vw) translateY(-11vh) scale(0.74); }
  10.71429% {
    transform: translateX(42vw) translateY(11vh) scale(0.31); }
  14.28571% {
    transform: translateX(17vw) translateY(-21vh) scale(0.83); }
  17.85714% {
    transform: translateX(-46vw) translateY(-16vh) scale(0.55); }
  21.42857% {
    transform: translateX(33vw) translateY(-2vh) scale(0.51); }
  25% {
    transform: translateX(-49vw) translateY(49vh) scale(0.28); }
  28.57143% {
    transform: translateX(40vw) translateY(33vh) scale(0.48); }
  32.14286% {
    transform: translateX(-9vw) translateY(-16vh) scale(0.26); }
  35.71429% {
    transform: translateX(30vw) translateY(-23vh) scale(0.3); }
  39.28571% {
    transform: translateX(-16vw) translateY(-3vh) scale(0.98); }
  42.85714% {
    transform: translateX(22vw) translateY(-18vh) scale(0.97); }
  46.42857% {
    transform: translateX(24vw) translateY(-40vh) scale(0.47); }
  50% {
    transform: translateX(44vw) translateY(42vh) scale(0.75); }
  53.57143% {
    transform: translateX(45vw) translateY(43vh) scale(0.52); }
  57.14286% {
    transform: translateX(18vw) translateY(-31vh) scale(0.29); }
  60.71429% {
    transform: translateX(32vw) translateY(27vh) scale(0.96); }
  64.28571% {
    transform: translateX(-40vw) translateY(-23vh) scale(0.97); }
  67.85714% {
    transform: translateX(43vw) translateY(-26vh) scale(0.86); }
  71.42857% {
    transform: translateX(19vw) translateY(-35vh) scale(0.67); }
  75% {
    transform: translateX(35vw) translateY(36vh) scale(0.92); }
  78.57143% {
    transform: translateX(33vw) translateY(0vh) scale(0.26); }
  82.14286% {
    transform: translateX(3vw) translateY(-18vh) scale(0.81); }
  85.71429% {
    transform: translateX(-20vw) translateY(50vh) scale(0.81); }
  89.28571% {
    transform: translateX(-47vw) translateY(13vh) scale(0.62); }
  92.85714% {
    transform: translateX(-45vw) translateY(18vh) scale(0.93); }
  96.42857% {
    transform: translateX(44vw) translateY(-12vh) scale(0.77); }
  100% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.79); } }

.firefly:nth-child(13) {
  animation-name: move13; }
  .firefly:nth-child(13)::before {
    animation-duration: 18s; }
  .firefly:nth-child(13)::after {
    animation-duration: 18s, 8738ms;
    animation-delay: 0ms, 3428ms; }

@keyframes move13 {
  0% {
    transform: translateX(-41vw) translateY(44vh) scale(0.91); }
  4.7619% {
    transform: translateX(-18vw) translateY(-3vh) scale(0.58); }
  9.52381% {
    transform: translateX(12vw) translateY(35vh) scale(0.43); }
  14.28571% {
    transform: translateX(-12vw) translateY(-35vh) scale(0.99); }
  19.04762% {
    transform: translateX(11vw) translateY(-23vh) scale(0.49); }
  23.80952% {
    transform: translateX(13vw) translateY(6vh) scale(0.65); }
  28.57143% {
    transform: translateX(-37vw) translateY(41vh) scale(0.68); }
  33.33333% {
    transform: translateX(-37vw) translateY(-24vh) scale(0.48); }
  38.09524% {
    transform: translateX(26vw) translateY(1vh) scale(0.34); }
  42.85714% {
    transform: translateX(-28vw) translateY(-27vh) scale(0.61); }
  47.61905% {
    transform: translateX(47vw) translateY(-15vh) scale(0.74); }
  52.38095% {
    transform: translateX(40vw) translateY(-43vh) scale(0.46); }
  57.14286% {
    transform: translateX(33vw) translateY(-9vh) scale(0.39); }
  61.90476% {
    transform: translateX(36vw) translateY(36vh) scale(0.53); }
  66.66667% {
    transform: translateX(-39vw) translateY(-4vh) scale(0.29); }
  71.42857% {
    transform: translateX(-25vw) translateY(-28vh) scale(0.39); }
  76.19048% {
    transform: translateX(-43vw) translateY(-41vh) scale(0.56); }
  80.95238% {
    transform: translateX(34vw) translateY(-40vh) scale(0.3); }
  85.71429% {
    transform: translateX(-40vw) translateY(-2vh) scale(0.81); }
  90.47619% {
    transform: translateX(49vw) translateY(-5vh) scale(0.48); }
  95.2381% {
    transform: translateX(-14vw) translateY(-5vh) scale(0.53); }
  100% {
    transform: translateX(33vw) translateY(11vh) scale(0.9); } }

.firefly:nth-child(14) {
  animation-name: move14; }
  .firefly:nth-child(14)::before {
    animation-duration: 13s; }
  .firefly:nth-child(14)::after {
    animation-duration: 13s, 10505ms;
    animation-delay: 0ms, 2648ms; }

@keyframes move14 {
  0% {
    transform: translateX(-44vw) translateY(-39vh) scale(0.32); }
  5.88235% {
    transform: translateX(-29vw) translateY(7vh) scale(0.39); }
  11.76471% {
    transform: translateX(-29vw) translateY(24vh) scale(0.98); }
  17.64706% {
    transform: translateX(-21vw) translateY(32vh) scale(0.76); }
  23.52941% {
    transform: translateX(15vw) translateY(6vh) scale(0.54); }
  29.41176% {
    transform: translateX(30vw) translateY(-18vh) scale(0.9); }
  35.29412% {
    transform: translateX(9vw) translateY(34vh) scale(0.5); }
  41.17647% {
    transform: translateX(-26vw) translateY(10vh) scale(0.89); }
  47.05882% {
    transform: translateX(-32vw) translateY(-14vh) scale(0.56); }
  52.94118% {
    transform: translateX(-36vw) translateY(-35vh) scale(0.53); }
  58.82353% {
    transform: translateX(-12vw) translateY(0vh) scale(0.43); }
  64.70588% {
    transform: translateX(34vw) translateY(37vh) scale(0.56); }
  70.58824% {
    transform: translateX(19vw) translateY(25vh) scale(0.68); }
  76.47059% {
    transform: translateX(0vw) translateY(46vh) scale(0.56); }
  82.35294% {
    transform: translateX(-17vw) translateY(7vh) scale(0.88); }
  88.23529% {
    transform: translateX(35vw) translateY(32vh) scale(0.9); }
  94.11765% {
    transform: translateX(40vw) translateY(-1vh) scale(0.37); }
  100% {
    transform: translateX(-44vw) translateY(-2vh) scale(0.5); } }

.firefly:nth-child(15) {
  animation-name: move15; }
  .firefly:nth-child(15)::before {
    animation-duration: 10s; }
  .firefly:nth-child(15)::after {
    animation-duration: 10s, 8609ms;
    animation-delay: 0ms, 4621ms; }

@keyframes move15 {
  0% {
    transform: translateX(-31vw) translateY(-18vh) scale(0.57); }
  5.26316% {
    transform: translateX(2vw) translateY(-31vh) scale(0.89); }
  10.52632% {
    transform: translateX(28vw) translateY(0vh) scale(0.92); }
  15.78947% {
    transform: translateX(-1vw) translateY(-32vh) scale(0.46); }
  21.05263% {
    transform: translateX(-11vw) translateY(11vh) scale(0.38); }
  26.31579% {
    transform: translateX(39vw) translateY(13vh) scale(0.47); }
  31.57895% {
    transform: translateX(7vw) translateY(20vh) scale(0.88); }
  36.84211% {
    transform: translateX(-47vw) translateY(-17vh) scale(0.26); }
  42.10526% {
    transform: translateX(-35vw) translateY(11vh) scale(0.71); }
  47.36842% {
    transform: translateX(8vw) translateY(30vh) scale(0.27); }
  52.63158% {
    transform: translateX(9vw) translateY(-40vh) scale(0.96); }
  57.89474% {
    transform: translateX(-46vw) translateY(-33vh) scale(0.45); }
  63.15789% {
    transform: translateX(27vw) translateY(-17vh) scale(0.91); }
  68.42105% {
    transform: translateX(-45vw) translateY(39vh) scale(0.61); }
  73.68421% {
    transform: translateX(-47vw) translateY(16vh) scale(0.95); }
  78.94737% {
    transform: translateX(-16vw) translateY(-30vh) scale(0.56); }
  84.21053% {
    transform: translateX(17vw) translateY(1vh) scale(0.67); }
  89.47368% {
    transform: translateX(-30vw) translateY(-23vh) scale(0.97); }
  94.73684% {
    transform: translateX(27vw) translateY(-47vh) scale(0.72); }
  100% {
    transform: translateX(-39vw) translateY(42vh) scale(0.92); } }

@keyframes drift {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow; }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow; } }





/* FireFlys */
.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none; }
  .firefly::before, .firefly::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw; }
  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite; }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite; }

.firefly:nth-child(1) {
  animation-name: move1; }
  .firefly:nth-child(1)::before {
    animation-duration: 10s; }
  .firefly:nth-child(1)::after {
    animation-duration: 10s, 6255ms;
    animation-delay: 0ms, 1752ms; }

@keyframes move1 {
  0% {
    transform: translateX(4vw) translateY(35vh) scale(0.52); }
  3.57143% {
    transform: translateX(17vw) translateY(-41vh) scale(0.56); }
  7.14286% {
    transform: translateX(-40vw) translateY(-41vh) scale(0.53); }
  10.71429% {
    transform: translateX(-5vw) translateY(30vh) scale(0.57); }
  14.28571% {
    transform: translateX(-30vw) translateY(32vh) scale(0.43); }
  17.85714% {
    transform: translateX(42vw) translateY(15vh) scale(0.55); }
  21.42857% {
    transform: translateX(-24vw) translateY(-18vh) scale(0.69); }
  25% {
    transform: translateX(40vw) translateY(50vh) scale(0.94); }
  28.57143% {
    transform: translateX(7vw) translateY(8vh) scale(0.38); }
  32.14286% {
    transform: translateX(-41vw) translateY(34vh) scale(0.54); }
  35.71429% {
    transform: translateX(-31vw) translateY(16vh) scale(0.29); }
  39.28571% {
    transform: translateX(47vw) translateY(-24vh) scale(0.91); }
  42.85714% {
    transform: translateX(-42vw) translateY(19vh) scale(0.51); }
  46.42857% {
    transform: translateX(-4vw) translateY(22vh) scale(0.68); }
  50% {
    transform: translateX(36vw) translateY(-38vh) scale(0.7); }
  53.57143% {
    transform: translateX(12vw) translateY(38vh) scale(0.4); }
  57.14286% {
    transform: translateX(31vw) translateY(-34vh) scale(0.46); }
  60.71429% {
    transform: translateX(21vw) translateY(-16vh) scale(0.93); }
  64.28571% {
    transform: translateX(41vw) translateY(17vh) scale(0.66); }
  67.85714% {
    transform: translateX(-34vw) translateY(-49vh) scale(0.7); }
  71.42857% {
    transform: translateX(8vw) translateY(-46vh) scale(0.46); }
  75% {
    transform: translateX(18vw) translateY(44vh) scale(0.34); }
  78.57143% {
    transform: translateX(38vw) translateY(-46vh) scale(0.98); }
  82.14286% {
    transform: translateX(24vw) translateY(-28vh) scale(0.95); }
  85.71429% {
    transform: translateX(-22vw) translateY(21vh) scale(0.67); }
  89.28571% {
    transform: translateX(29vw) translateY(6vh) scale(0.52); }
  92.85714% {
    transform: translateX(16vw) translateY(-42vh) scale(0.52); }
  96.42857% {
    transform: translateX(13vw) translateY(-24vh) scale(0.8); }
  100% {
    transform: translateX(5vw) translateY(-4vh) scale(0.35); } }

.firefly:nth-child(2) {
  animation-name: move2; }
  .firefly:nth-child(2)::before {
    animation-duration: 15s; }
  .firefly:nth-child(2)::after {
    animation-duration: 15s, 7203ms;
    animation-delay: 0ms, 3039ms; }

@keyframes move2 {
  0% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.89); }
  4.16667% {
    transform: translateX(-38vw) translateY(35vh) scale(0.73); }
  8.33333% {
    transform: translateX(15vw) translateY(-48vh) scale(0.65); }
  12.5% {
    transform: translateX(8vw) translateY(-3vh) scale(0.26); }
  16.66667% {
    transform: translateX(-22vw) translateY(-43vh) scale(0.96); }
  20.83333% {
    transform: translateX(8vw) translateY(3vh) scale(0.88); }
  25% {
    transform: translateX(3vw) translateY(-34vh) scale(0.44); }
  29.16667% {
    transform: translateX(-36vw) translateY(10vh) scale(0.46); }
  33.33333% {
    transform: translateX(-47vw) translateY(-40vh) scale(0.77); }
  37.5% {
    transform: translateX(2vw) translateY(6vh) scale(0.58); }
  41.66667% {
    transform: translateX(30vw) translateY(45vh) scale(0.86); }
  45.83333% {
    transform: translateX(42vw) translateY(36vh) scale(0.79); }
  50% {
    transform: translateX(22vw) translateY(29vh) scale(0.67); }
  54.16667% {
    transform: translateX(-34vw) translateY(-24vh) scale(0.75); }
  58.33333% {
    transform: translateX(31vw) translateY(47vh) scale(0.78); }
  62.5% {
    transform: translateX(-21vw) translateY(-29vh) scale(0.57); }
  66.66667% {
    transform: translateX(-25vw) translateY(6vh) scale(0.32); }
  70.83333% {
    transform: translateX(7vw) translateY(41vh) scale(0.39); }
  75% {
    transform: translateX(-36vw) translateY(-42vh) scale(0.78); }
  79.16667% {
    transform: translateX(-44vw) translateY(16vh) scale(0.93); }
  83.33333% {
    transform: translateX(44vw) translateY(-2vh) scale(0.4); }
  87.5% {
    transform: translateX(18vw) translateY(28vh) scale(0.38); }
  91.66667% {
    transform: translateX(-1vw) translateY(46vh) scale(0.83); }
  95.83333% {
    transform: translateX(6vw) translateY(-28vh) scale(0.51); }
  100% {
    transform: translateX(-3vw) translateY(-44vh) scale(0.63); } }

.firefly:nth-child(3) {
  animation-name: move3; }
  .firefly:nth-child(3)::before {
    animation-duration: 12s; }
  .firefly:nth-child(3)::after {
    animation-duration: 12s, 5673ms;
    animation-delay: 0ms, 4786ms; }

@keyframes move3 {
  0% {
    transform: translateX(-28vw) translateY(-24vh) scale(0.6); }
  4.7619% {
    transform: translateX(24vw) translateY(-43vh) scale(0.37); }
  9.52381% {
    transform: translateX(-46vw) translateY(28vh) scale(0.57); }
  14.28571% {
    transform: translateX(-25vw) translateY(42vh) scale(0.54); }
  19.04762% {
    transform: translateX(-3vw) translateY(-46vh) scale(0.54); }
  23.80952% {
    transform: translateX(-32vw) translateY(37vh) scale(0.66); }
  28.57143% {
    transform: translateX(40vw) translateY(-38vh) scale(0.6); }
  33.33333% {
    transform: translateX(0vw) translateY(28vh) scale(0.75); }
  38.09524% {
    transform: translateX(-27vw) translateY(-28vh) scale(0.67); }
  42.85714% {
    transform: translateX(35vw) translateY(-8vh) scale(0.27); }
  47.61905% {
    transform: translateX(-4vw) translateY(-49vh) scale(0.67); }
  52.38095% {
    transform: translateX(37vw) translateY(23vh) scale(0.64); }
  57.14286% {
    transform: translateX(44vw) translateY(-33vh) scale(0.41); }
  61.90476% {
    transform: translateX(-7vw) translateY(-14vh) scale(0.67); }
  66.66667% {
    transform: translateX(45vw) translateY(12vh) scale(0.78); }
  71.42857% {
    transform: translateX(11vw) translateY(-7vh) scale(0.84); }
  76.19048% {
    transform: translateX(-33vw) translateY(-36vh) scale(0.57); }
  80.95238% {
    transform: translateX(33vw) translateY(-46vh) scale(0.78); }
  85.71429% {
    transform: translateX(-17vw) translateY(7vh) scale(0.31); }
  90.47619% {
    transform: translateX(-23vw) translateY(-49vh) scale(0.96); }
  95.2381% {
    transform: translateX(1vw) translateY(18vh) scale(0.75); }
  100% {
    transform: translateX(18vw) translateY(-36vh) scale(0.96); } }

.firefly:nth-child(4) {
  animation-name: move4; }
  .firefly:nth-child(4)::before {
    animation-duration: 9s; }
  .firefly:nth-child(4)::after {
    animation-duration: 9s, 5985ms;
    animation-delay: 0ms, 4746ms; }

@keyframes move4 {
  0% {
    transform: translateX(2vw) translateY(-6vh) scale(0.96); }
  4.54545% {
    transform: translateX(27vw) translateY(15vh) scale(0.88); }
  9.09091% {
    transform: translateX(-4vw) translateY(-27vh) scale(0.61); }
  13.63636% {
    transform: translateX(-27vw) translateY(43vh) scale(0.4); }
  18.18182% {
    transform: translateX(32vw) translateY(21vh) scale(0.74); }
  22.72727% {
    transform: translateX(5vw) translateY(19vh) scale(0.94); }
  27.27273% {
    transform: translateX(28vw) translateY(-32vh) scale(0.5); }
  31.81818% {
    transform: translateX(17vw) translateY(16vh) scale(0.53); }
  36.36364% {
    transform: translateX(4vw) translateY(-21vh) scale(0.32); }
  40.90909% {
    transform: translateX(-8vw) translateY(44vh) scale(0.57); }
  45.45455% {
    transform: translateX(-22vw) translateY(-2vh) scale(0.79); }
  50% {
    transform: translateX(-7vw) translateY(4vh) scale(0.64); }
  54.54545% {
    transform: translateX(-26vw) translateY(-14vh) scale(0.8); }
  59.09091% {
    transform: translateX(-5vw) translateY(21vh) scale(0.95); }
  63.63636% {
    transform: translateX(-24vw) translateY(-22vh) scale(0.74); }
  68.18182% {
    transform: translateX(17vw) translateY(-30vh) scale(0.32); }
  72.72727% {
    transform: translateX(-16vw) translateY(13vh) scale(0.72); }
  77.27273% {
    transform: translateX(-38vw) translateY(29vh) scale(0.89); }
  81.81818% {
    transform: translateX(48vw) translateY(4vh) scale(0.79); }
  86.36364% {
    transform: translateX(18vw) translateY(-48vh) scale(0.68); }
  90.90909% {
    transform: translateX(-25vw) translateY(1vh) scale(0.96); }
  95.45455% {
    transform: translateX(-24vw) translateY(10vh) scale(0.75); }
  100% {
    transform: translateX(9vw) translateY(-27vh) scale(0.7); } }

.firefly:nth-child(5) {
  animation-name: move5; }
  .firefly:nth-child(5)::before {
    animation-duration: 17s; }
  .firefly:nth-child(5)::after {
    animation-duration: 17s, 5802ms;
    animation-delay: 0ms, 4434ms; }

@keyframes move5 {
  0% {
    transform: translateX(-5vw) translateY(-13vh) scale(0.66); }
  3.57143% {
    transform: translateX(11vw) translateY(-22vh) scale(0.49); }
  7.14286% {
    transform: translateX(-26vw) translateY(37vh) scale(0.35); }
  10.71429% {
    transform: translateX(-20vw) translateY(30vh) scale(0.39); }
  14.28571% {
    transform: translateX(-27vw) translateY(13vh) scale(0.84); }
  17.85714% {
    transform: translateX(28vw) translateY(31vh) scale(0.62); }
  21.42857% {
    transform: translateX(17vw) translateY(-48vh) scale(0.88); }
  25% {
    transform: translateX(27vw) translateY(-22vh) scale(0.58); }
  28.57143% {
    transform: translateX(-5vw) translateY(-15vh) scale(0.8); }
  32.14286% {
    transform: translateX(38vw) translateY(7vh) scale(0.94); }
  35.71429% {
    transform: translateX(15vw) translateY(-5vh) scale(0.55); }
  39.28571% {
    transform: translateX(-15vw) translateY(-7vh) scale(0.57); }
  42.85714% {
    transform: translateX(-19vw) translateY(-21vh) scale(0.72); }
  46.42857% {
    transform: translateX(1vw) translateY(42vh) scale(0.81); }
  50% {
    transform: translateX(-33vw) translateY(-48vh) scale(0.89); }
  53.57143% {
    transform: translateX(-23vw) translateY(13vh) scale(0.32); }
  57.14286% {
    transform: translateX(46vw) translateY(-7vh) scale(0.87); }
  60.71429% {
    transform: translateX(-47vw) translateY(-40vh) scale(0.34); }
  64.28571% {
    transform: translateX(-47vw) translateY(-32vh) scale(0.48); }
  67.85714% {
    transform: translateX(29vw) translateY(-16vh) scale(0.93); }
  71.42857% {
    transform: translateX(-19vw) translateY(17vh) scale(0.57); }
  75% {
    transform: translateX(9vw) translateY(31vh) scale(0.59); }
  78.57143% {
    transform: translateX(21vw) translateY(41vh) scale(0.85); }
  82.14286% {
    transform: translateX(2vw) translateY(-23vh) scale(0.87); }
  85.71429% {
    transform: translateX(19vw) translateY(18vh) scale(0.83); }
  89.28571% {
    transform: translateX(-9vw) translateY(45vh) scale(0.34); }
  92.85714% {
    transform: translateX(7vw) translateY(-1vh) scale(0.89); }
  96.42857% {
    transform: translateX(-32vw) translateY(-47vh) scale(0.73); }
  100% {
    transform: translateX(-48vw) translateY(-6vh) scale(0.98); } }

.firefly:nth-child(6) {
  animation-name: move6; }
  .firefly:nth-child(6)::before {
    animation-duration: 16s; }
  .firefly:nth-child(6)::after {
    animation-duration: 16s, 10219ms;
    animation-delay: 0ms, 4179ms; }

@keyframes move6 {
  0% {
    transform: translateX(26vw) translateY(49vh) scale(0.34); }
  3.7037% {
    transform: translateX(-7vw) translateY(-40vh) scale(0.35); }
  7.40741% {
    transform: translateX(-28vw) translateY(25vh) scale(0.47); }
  11.11111% {
    transform: translateX(27vw) translateY(10vh) scale(0.52); }
  14.81481% {
    transform: translateX(31vw) translateY(-23vh) scale(0.87); }
  18.51852% {
    transform: translateX(-13vw) translateY(-5vh) scale(0.92); }
  22.22222% {
    transform: translateX(-21vw) translateY(35vh) scale(0.93); }
  25.92593% {
    transform: translateX(31vw) translateY(32vh) scale(0.97); }
  29.62963% {
    transform: translateX(44vw) translateY(27vh) scale(0.34); }
  33.33333% {
    transform: translateX(-15vw) translateY(6vh) scale(0.9); }
  37.03704% {
    transform: translateX(40vw) translateY(11vh) scale(0.58); }
  40.74074% {
    transform: translateX(-8vw) translateY(35vh) scale(0.33); }
  44.44444% {
    transform: translateX(-40vw) translateY(-46vh) scale(0.56); }
  48.14815% {
    transform: translateX(-15vw) translateY(-4vh) scale(0.44); }
  51.85185% {
    transform: translateX(-49vw) translateY(-43vh) scale(1); }
  55.55556% {
    transform: translateX(39vw) translateY(49vh) scale(0.28); }
  59.25926% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.85); }
  62.96296% {
    transform: translateX(-40vw) translateY(42vh) scale(0.71); }
  66.66667% {
    transform: translateX(38vw) translateY(26vh) scale(0.34); }
  70.37037% {
    transform: translateX(31vw) translateY(5vh) scale(0.47); }
  74.07407% {
    transform: translateX(18vw) translateY(-7vh) scale(0.74); }
  77.77778% {
    transform: translateX(39vw) translateY(-38vh) scale(0.81); }
  81.48148% {
    transform: translateX(48vw) translateY(-35vh) scale(0.88); }
  85.18519% {
    transform: translateX(47vw) translateY(-9vh) scale(0.57); }
  88.88889% {
    transform: translateX(3vw) translateY(-35vh) scale(0.77); }
  92.59259% {
    transform: translateX(43vw) translateY(-10vh) scale(0.48); }
  96.2963% {
    transform: translateX(15vw) translateY(-22vh) scale(0.42); }
  100% {
    transform: translateX(49vw) translateY(-28vh) scale(0.76); } }

.firefly:nth-child(7) {
  animation-name: move7; }
  .firefly:nth-child(7)::before {
    animation-duration: 14s; }
  .firefly:nth-child(7)::after {
    animation-duration: 14s, 8644ms;
    animation-delay: 0ms, 7908ms; }

@keyframes move7 {
  0% {
    transform: translateX(7vw) translateY(28vh) scale(0.85); }
  5% {
    transform: translateX(49vw) translateY(0vh) scale(0.39); }
  10% {
    transform: translateX(38vw) translateY(3vh) scale(0.48); }
  15% {
    transform: translateX(11vw) translateY(-16vh) scale(0.47); }
  20% {
    transform: translateX(11vw) translateY(-35vh) scale(0.56); }
  25% {
    transform: translateX(-3vw) translateY(-31vh) scale(0.49); }
  30% {
    transform: translateX(-14vw) translateY(21vh) scale(0.77); }
  35% {
    transform: translateX(-41vw) translateY(-6vh) scale(0.9); }
  40% {
    transform: translateX(-16vw) translateY(-37vh) scale(0.72); }
  45% {
    transform: translateX(11vw) translateY(34vh) scale(0.77); }
  50% {
    transform: translateX(46vw) translateY(-32vh) scale(0.59); }
  55% {
    transform: translateX(-19vw) translateY(-3vh) scale(0.94); }
  60% {
    transform: translateX(-30vw) translateY(-44vh) scale(0.78); }
  65% {
    transform: translateX(12vw) translateY(-31vh) scale(0.62); }
  70% {
    transform: translateX(31vw) translateY(5vh) scale(0.35); }
  75% {
    transform: translateX(-29vw) translateY(-38vh) scale(0.56); }
  80% {
    transform: translateX(-3vw) translateY(12vh) scale(0.97); }
  85% {
    transform: translateX(-3vw) translateY(-39vh) scale(0.32); }
  90% {
    transform: translateX(-5vw) translateY(24vh) scale(0.42); }
  95% {
    transform: translateX(-27vw) translateY(44vh) scale(0.28); }
  100% {
    transform: translateX(16vw) translateY(10vh) scale(0.4); } }

.firefly:nth-child(8) {
  animation-name: move8; }
  .firefly:nth-child(8)::before {
    animation-duration: 16s; }
  .firefly:nth-child(8)::after {
    animation-duration: 16s, 9601ms;
    animation-delay: 0ms, 6955ms; }

@keyframes move8 {
  0% {
    transform: translateX(7vw) translateY(-28vh) scale(0.67); }
  5.55556% {
    transform: translateX(-14vw) translateY(25vh) scale(0.64); }
  11.11111% {
    transform: translateX(4vw) translateY(33vh) scale(0.37); }
  16.66667% {
    transform: translateX(18vw) translateY(-37vh) scale(0.72); }
  22.22222% {
    transform: translateX(6vw) translateY(-43vh) scale(0.96); }
  27.77778% {
    transform: translateX(0vw) translateY(-27vh) scale(0.37); }
  33.33333% {
    transform: translateX(7vw) translateY(26vh) scale(0.63); }
  38.88889% {
    transform: translateX(33vw) translateY(-24vh) scale(0.57); }
  44.44444% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.89); }
  50% {
    transform: translateX(-23vw) translateY(-45vh) scale(0.53); }
  55.55556% {
    transform: translateX(-14vw) translateY(-15vh) scale(0.95); }
  61.11111% {
    transform: translateX(-35vw) translateY(-43vh) scale(0.76); }
  66.66667% {
    transform: translateX(1vw) translateY(-24vh) scale(0.83); }
  72.22222% {
    transform: translateX(-20vw) translateY(37vh) scale(0.87); }
  77.77778% {
    transform: translateX(20vw) translateY(-24vh) scale(0.59); }
  83.33333% {
    transform: translateX(22vw) translateY(24vh) scale(0.79); }
  88.88889% {
    transform: translateX(33vw) translateY(-46vh) scale(0.97); }
  94.44444% {
    transform: translateX(-18vw) translateY(3vh) scale(0.66); }
  100% {
    transform: translateX(7vw) translateY(-49vh) scale(0.91); } }

.firefly:nth-child(9) {
  animation-name: move9; }
  .firefly:nth-child(9)::before {
    animation-duration: 18s; }
  .firefly:nth-child(9)::after {
    animation-duration: 18s, 5482ms;
    animation-delay: 0ms, 4153ms; }

@keyframes move9 {
  0% {
    transform: translateX(38vw) translateY(-8vh) scale(0.62); }
  3.57143% {
    transform: translateX(14vw) translateY(-43vh) scale(0.91); }
  7.14286% {
    transform: translateX(49vw) translateY(4vh) scale(0.55); }
  10.71429% {
    transform: translateX(-27vw) translateY(-24vh) scale(0.26); }
  14.28571% {
    transform: translateX(-32vw) translateY(15vh) scale(0.78); }
  17.85714% {
    transform: translateX(41vw) translateY(40vh) scale(0.87); }
  21.42857% {
    transform: translateX(12vw) translateY(-4vh) scale(0.36); }
  25% {
    transform: translateX(-12vw) translateY(-40vh) scale(0.37); }
  28.57143% {
    transform: translateX(-27vw) translateY(30vh) scale(0.71); }
  32.14286% {
    transform: translateX(-17vw) translateY(39vh) scale(0.44); }
  35.71429% {
    transform: translateX(27vw) translateY(30vh) scale(0.88); }
  39.28571% {
    transform: translateX(-30vw) translateY(-16vh) scale(0.95); }
  42.85714% {
    transform: translateX(7vw) translateY(11vh) scale(0.38); }
  46.42857% {
    transform: translateX(-29vw) translateY(16vh) scale(0.27); }
  50% {
    transform: translateX(-47vw) translateY(40vh) scale(0.35); }
  53.57143% {
    transform: translateX(32vw) translateY(15vh) scale(0.57); }
  57.14286% {
    transform: translateX(21vw) translateY(30vh) scale(0.86); }
  60.71429% {
    transform: translateX(8vw) translateY(36vh) scale(0.81); }
  64.28571% {
    transform: translateX(11vw) translateY(-12vh) scale(0.33); }
  67.85714% {
    transform: translateX(21vw) translateY(45vh) scale(0.92); }
  71.42857% {
    transform: translateX(-12vw) translateY(-28vh) scale(0.56); }
  75% {
    transform: translateX(5vw) translateY(30vh) scale(0.67); }
  78.57143% {
    transform: translateX(-46vw) translateY(25vh) scale(0.93); }
  82.14286% {
    transform: translateX(-47vw) translateY(41vh) scale(0.44); }
  85.71429% {
    transform: translateX(-28vw) translateY(21vh) scale(0.28); }
  89.28571% {
    transform: translateX(-7vw) translateY(-29vh) scale(0.43); }
  92.85714% {
    transform: translateX(-23vw) translateY(-22vh) scale(0.77); }
  96.42857% {
    transform: translateX(-28vw) translateY(-27vh) scale(0.94); }
  100% {
    transform: translateX(47vw) translateY(29vh) scale(0.49); } }

.firefly:nth-child(10) {
  animation-name: move10; }
  .firefly:nth-child(10)::before {
    animation-duration: 11s; }
  .firefly:nth-child(10)::after {
    animation-duration: 11s, 10884ms;
    animation-delay: 0ms, 5264ms; }

@keyframes move10 {
  0% {
    transform: translateX(1vw) translateY(12vh) scale(0.82); }
  5.55556% {
    transform: translateX(31vw) translateY(26vh) scale(0.67); }
  11.11111% {
    transform: translateX(-49vw) translateY(-41vh) scale(0.93); }
  16.66667% {
    transform: translateX(15vw) translateY(5vh) scale(0.47); }
  22.22222% {
    transform: translateX(40vw) translateY(44vh) scale(0.76); }
  27.77778% {
    transform: translateX(17vw) translateY(-10vh) scale(0.41); }
  33.33333% {
    transform: translateX(-11vw) translateY(49vh) scale(0.63); }
  38.88889% {
    transform: translateX(15vw) translateY(21vh) scale(0.84); }
  44.44444% {
    transform: translateX(-25vw) translateY(22vh) scale(0.96); }
  50% {
    transform: translateX(40vw) translateY(-31vh) scale(0.9); }
  55.55556% {
    transform: translateX(-20vw) translateY(19vh) scale(0.75); }
  61.11111% {
    transform: translateX(-13vw) translateY(-18vh) scale(0.48); }
  66.66667% {
    transform: translateX(50vw) translateY(17vh) scale(0.98); }
  72.22222% {
    transform: translateX(-22vw) translateY(-3vh) scale(0.53); }
  77.77778% {
    transform: translateX(30vw) translateY(24vh) scale(0.79); }
  83.33333% {
    transform: translateX(-31vw) translateY(15vh) scale(0.5); }
  88.88889% {
    transform: translateX(-37vw) translateY(9vh) scale(0.62); }
  94.44444% {
    transform: translateX(-42vw) translateY(44vh) scale(0.55); }
  100% {
    transform: translateX(-42vw) translateY(-37vh) scale(0.35); } }

.firefly:nth-child(11) {
  animation-name: move11; }
  .firefly:nth-child(11)::before {
    animation-duration: 18s; }
  .firefly:nth-child(11)::after {
    animation-duration: 18s, 9632ms;
    animation-delay: 0ms, 4348ms; }

@keyframes move11 {
  0% {
    transform: translateX(-28vw) translateY(-17vh) scale(0.7); }
  4% {
    transform: translateX(-27vw) translateY(-38vh) scale(0.77); }
  8% {
    transform: translateX(-2vw) translateY(45vh) scale(0.82); }
  12% {
    transform: translateX(7vw) translateY(-11vh) scale(0.72); }
  16% {
    transform: translateX(-26vw) translateY(-46vh) scale(0.92); }
  20% {
    transform: translateX(18vw) translateY(11vh) scale(0.52); }
  24% {
    transform: translateX(-16vw) translateY(-39vh) scale(0.92); }
  28% {
    transform: translateX(50vw) translateY(-15vh) scale(0.98); }
  32% {
    transform: translateX(4vw) translateY(-23vh) scale(0.37); }
  36% {
    transform: translateX(8vw) translateY(-16vh) scale(0.98); }
  40% {
    transform: translateX(21vw) translateY(-8vh) scale(0.92); }
  44% {
    transform: translateX(-45vw) translateY(-28vh) scale(0.88); }
  48% {
    transform: translateX(-26vw) translateY(-39vh) scale(0.7); }
  52% {
    transform: translateX(21vw) translateY(-39vh) scale(1); }
  56% {
    transform: translateX(37vw) translateY(-35vh) scale(0.43); }
  60% {
    transform: translateX(-31vw) translateY(34vh) scale(0.6); }
  64% {
    transform: translateX(-8vw) translateY(-14vh) scale(0.49); }
  68% {
    transform: translateX(-2vw) translateY(44vh) scale(0.28); }
  72% {
    transform: translateX(36vw) translateY(-28vh) scale(0.45); }
  76% {
    transform: translateX(48vw) translateY(-28vh) scale(0.54); }
  80% {
    transform: translateX(-6vw) translateY(-1vh) scale(0.94); }
  84% {
    transform: translateX(0vw) translateY(-45vh) scale(0.99); }
  88% {
    transform: translateX(0vw) translateY(24vh) scale(0.66); }
  92% {
    transform: translateX(45vw) translateY(10vh) scale(0.29); }
  96% {
    transform: translateX(15vw) translateY(-12vh) scale(0.37); }
  100% {
    transform: translateX(42vw) translateY(-11vh) scale(0.62); } }

.firefly:nth-child(12) {
  animation-name: move12; }
  .firefly:nth-child(12)::before {
    animation-duration: 13s; }
  .firefly:nth-child(12)::after {
    animation-duration: 13s, 9843ms;
    animation-delay: 0ms, 5196ms; }

@keyframes move12 {
  0% {
    transform: translateX(-46vw) translateY(-11vh) scale(0.97); }
  3.57143% {
    transform: translateX(-29vw) translateY(10vh) scale(0.97); }
  7.14286% {
    transform: translateX(-7vw) translateY(-11vh) scale(0.74); }
  10.71429% {
    transform: translateX(42vw) translateY(11vh) scale(0.31); }
  14.28571% {
    transform: translateX(17vw) translateY(-21vh) scale(0.83); }
  17.85714% {
    transform: translateX(-46vw) translateY(-16vh) scale(0.55); }
  21.42857% {
    transform: translateX(33vw) translateY(-2vh) scale(0.51); }
  25% {
    transform: translateX(-49vw) translateY(49vh) scale(0.28); }
  28.57143% {
    transform: translateX(40vw) translateY(33vh) scale(0.48); }
  32.14286% {
    transform: translateX(-9vw) translateY(-16vh) scale(0.26); }
  35.71429% {
    transform: translateX(30vw) translateY(-23vh) scale(0.3); }
  39.28571% {
    transform: translateX(-16vw) translateY(-3vh) scale(0.98); }
  42.85714% {
    transform: translateX(22vw) translateY(-18vh) scale(0.97); }
  46.42857% {
    transform: translateX(24vw) translateY(-40vh) scale(0.47); }
  50% {
    transform: translateX(44vw) translateY(42vh) scale(0.75); }
  53.57143% {
    transform: translateX(45vw) translateY(43vh) scale(0.52); }
  57.14286% {
    transform: translateX(18vw) translateY(-31vh) scale(0.29); }
  60.71429% {
    transform: translateX(32vw) translateY(27vh) scale(0.96); }
  64.28571% {
    transform: translateX(-40vw) translateY(-23vh) scale(0.97); }
  67.85714% {
    transform: translateX(43vw) translateY(-26vh) scale(0.86); }
  71.42857% {
    transform: translateX(19vw) translateY(-35vh) scale(0.67); }
  75% {
    transform: translateX(35vw) translateY(36vh) scale(0.92); }
  78.57143% {
    transform: translateX(33vw) translateY(0vh) scale(0.26); }
  82.14286% {
    transform: translateX(3vw) translateY(-18vh) scale(0.81); }
  85.71429% {
    transform: translateX(-20vw) translateY(50vh) scale(0.81); }
  89.28571% {
    transform: translateX(-47vw) translateY(13vh) scale(0.62); }
  92.85714% {
    transform: translateX(-45vw) translateY(18vh) scale(0.93); }
  96.42857% {
    transform: translateX(44vw) translateY(-12vh) scale(0.77); }
  100% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.79); } }

.firefly:nth-child(13) {
  animation-name: move13; }
  .firefly:nth-child(13)::before {
    animation-duration: 18s; }
  .firefly:nth-child(13)::after {
    animation-duration: 18s, 8738ms;
    animation-delay: 0ms, 3428ms; }

@keyframes move13 {
  0% {
    transform: translateX(-41vw) translateY(44vh) scale(0.91); }
  4.7619% {
    transform: translateX(-18vw) translateY(-3vh) scale(0.58); }
  9.52381% {
    transform: translateX(12vw) translateY(35vh) scale(0.43); }
  14.28571% {
    transform: translateX(-12vw) translateY(-35vh) scale(0.99); }
  19.04762% {
    transform: translateX(11vw) translateY(-23vh) scale(0.49); }
  23.80952% {
    transform: translateX(13vw) translateY(6vh) scale(0.65); }
  28.57143% {
    transform: translateX(-37vw) translateY(41vh) scale(0.68); }
  33.33333% {
    transform: translateX(-37vw) translateY(-24vh) scale(0.48); }
  38.09524% {
    transform: translateX(26vw) translateY(1vh) scale(0.34); }
  42.85714% {
    transform: translateX(-28vw) translateY(-27vh) scale(0.61); }
  47.61905% {
    transform: translateX(47vw) translateY(-15vh) scale(0.74); }
  52.38095% {
    transform: translateX(40vw) translateY(-43vh) scale(0.46); }
  57.14286% {
    transform: translateX(33vw) translateY(-9vh) scale(0.39); }
  61.90476% {
    transform: translateX(36vw) translateY(36vh) scale(0.53); }
  66.66667% {
    transform: translateX(-39vw) translateY(-4vh) scale(0.29); }
  71.42857% {
    transform: translateX(-25vw) translateY(-28vh) scale(0.39); }
  76.19048% {
    transform: translateX(-43vw) translateY(-41vh) scale(0.56); }
  80.95238% {
    transform: translateX(34vw) translateY(-40vh) scale(0.3); }
  85.71429% {
    transform: translateX(-40vw) translateY(-2vh) scale(0.81); }
  90.47619% {
    transform: translateX(49vw) translateY(-5vh) scale(0.48); }
  95.2381% {
    transform: translateX(-14vw) translateY(-5vh) scale(0.53); }
  100% {
    transform: translateX(33vw) translateY(11vh) scale(0.9); } }

.firefly:nth-child(14) {
  animation-name: move14; }
  .firefly:nth-child(14)::before {
    animation-duration: 13s; }
  .firefly:nth-child(14)::after {
    animation-duration: 13s, 10505ms;
    animation-delay: 0ms, 2648ms; }

@keyframes move14 {
  0% {
    transform: translateX(-44vw) translateY(-39vh) scale(0.32); }
  5.88235% {
    transform: translateX(-29vw) translateY(7vh) scale(0.39); }
  11.76471% {
    transform: translateX(-29vw) translateY(24vh) scale(0.98); }
  17.64706% {
    transform: translateX(-21vw) translateY(32vh) scale(0.76); }
  23.52941% {
    transform: translateX(15vw) translateY(6vh) scale(0.54); }
  29.41176% {
    transform: translateX(30vw) translateY(-18vh) scale(0.9); }
  35.29412% {
    transform: translateX(9vw) translateY(34vh) scale(0.5); }
  41.17647% {
    transform: translateX(-26vw) translateY(10vh) scale(0.89); }
  47.05882% {
    transform: translateX(-32vw) translateY(-14vh) scale(0.56); }
  52.94118% {
    transform: translateX(-36vw) translateY(-35vh) scale(0.53); }
  58.82353% {
    transform: translateX(-12vw) translateY(0vh) scale(0.43); }
  64.70588% {
    transform: translateX(34vw) translateY(37vh) scale(0.56); }
  70.58824% {
    transform: translateX(19vw) translateY(25vh) scale(0.68); }
  76.47059% {
    transform: translateX(0vw) translateY(46vh) scale(0.56); }
  82.35294% {
    transform: translateX(-17vw) translateY(7vh) scale(0.88); }
  88.23529% {
    transform: translateX(35vw) translateY(32vh) scale(0.9); }
  94.11765% {
    transform: translateX(40vw) translateY(-1vh) scale(0.37); }
  100% {
    transform: translateX(-44vw) translateY(-2vh) scale(0.5); } }

.firefly:nth-child(15) {
  animation-name: move15; }
  .firefly:nth-child(15)::before {
    animation-duration: 10s; }
  .firefly:nth-child(15)::after {
    animation-duration: 10s, 8609ms;
    animation-delay: 0ms, 4621ms; }

@keyframes move15 {
  0% {
    transform: translateX(-31vw) translateY(-18vh) scale(0.57); }
  5.26316% {
    transform: translateX(2vw) translateY(-31vh) scale(0.89); }
  10.52632% {
    transform: translateX(28vw) translateY(0vh) scale(0.92); }
  15.78947% {
    transform: translateX(-1vw) translateY(-32vh) scale(0.46); }
  21.05263% {
    transform: translateX(-11vw) translateY(11vh) scale(0.38); }
  26.31579% {
    transform: translateX(39vw) translateY(13vh) scale(0.47); }
  31.57895% {
    transform: translateX(7vw) translateY(20vh) scale(0.88); }
  36.84211% {
    transform: translateX(-47vw) translateY(-17vh) scale(0.26); }
  42.10526% {
    transform: translateX(-35vw) translateY(11vh) scale(0.71); }
  47.36842% {
    transform: translateX(8vw) translateY(30vh) scale(0.27); }
  52.63158% {
    transform: translateX(9vw) translateY(-40vh) scale(0.96); }
  57.89474% {
    transform: translateX(-46vw) translateY(-33vh) scale(0.45); }
  63.15789% {
    transform: translateX(27vw) translateY(-17vh) scale(0.91); }
  68.42105% {
    transform: translateX(-45vw) translateY(39vh) scale(0.61); }
  73.68421% {
    transform: translateX(-47vw) translateY(16vh) scale(0.95); }
  78.94737% {
    transform: translateX(-16vw) translateY(-30vh) scale(0.56); }
  84.21053% {
    transform: translateX(17vw) translateY(1vh) scale(0.67); }
  89.47368% {
    transform: translateX(-30vw) translateY(-23vh) scale(0.97); }
  94.73684% {
    transform: translateX(27vw) translateY(-47vh) scale(0.72); }
  100% {
    transform: translateX(-39vw) translateY(42vh) scale(0.92); } }

@keyframes drift {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow; }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow; } }


    .carda{
      text-decoration: none;

    }

    .cardPadding{
      padding-top: 0%
    }
  
    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
    }
    

    /* ------------------- CONTACT V2 CSS ----------------------- */
    /* CSS */
.button-40 {
  margin: 15px;
  width: 100% !important;
  height: 100px;
  background-color: transparent;
  border: #6B7280 1px solid;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.button-40:hover {
  background-color: rgb(144,28,124);
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .button-40 {
    padding: .75rem 1.5rem;
  }
}

.contact-icon-v2-container {
  margin: 15px;
  height: 100px;
  background-color: transparent;
  /* border: #6B7280 1px solid; */
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-icon-v2-container img {
  width: 50px;
  height: 50px;
}

.contact-icon-v2-container-mobile {
  margin: 15px;
  width: 100%;
  height: 100px;
  background-color: transparent;
  /* border: #6B7280 1px solid; */
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-icon-v2-container-mobile img {
  width: 50px;
  height: 50px;
}

.img-contact-mobile{
  margin: 7px;
}
